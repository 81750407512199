import React from 'react';
import {
  Box,
  Text,
  Button,
  Image,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const SquareCard = ({ title, imageUrl, link }) => {
  
  const cardSize = useBreakpointValue({
    base: '330px',
    sm: '240px',
    md: '280px',
    lg: '200px',
    xl: '230px',
    '2xl': '280px'
  });

  return (
    <Box
     _hover={{
                  background:"linear-gradient(180deg, #000104 0%, #233065 100%)",
                  boxShadow:"0px 8.04325px 86.0338px rgba(37, 72, 153, 0.1)",
                  color: "white"
                }}   
      width={cardSize}
      height={cardSize}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      background="#FFFFFF"
      boxShadow="0px 7.67776px 82.1243px rgba(37, 72, 153, 0.1);"
      borderRadius="15.3555px"
      overflow="hidden"
      py="10"
      px="3"
    >
      <Image
        src={imageUrl}
        alt={title}
        borderRadius="lg"
        objectFit="cover"
        width="80%"
        height="50%"
      />
      <VStack mt="1" spacing="1" flex="1">
        <Text
          fontFamily="Public Sans"
          fontStyle="normal"
          fontWeight="400"
          fontSize="14.0112px"
          lineHeight="20px"
          display="flex"
          alignItems="center"
          textAlign="center"
          width={'auto'}
        >
          {title}
        </Text>
        <Link to={link}>
          <Button width={'auto'} mt="2"  variant="solid" w="100%" color="#233065">
            See more
          </Button>
        </Link>
      </VStack>
    </Box>
  );
};

export default SquareCard;
