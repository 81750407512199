import React, { useState, useEffect } from 'react';
import {
  Box, SimpleGrid, Image, Center,
  Text, Hide, Button, Card,
  Stack, Input, CardBody, FormControl, FormLabel, Textarea, Flex
} from '@chakra-ui/react';
import Header from '../components/headerComponent';
import Footer from '../components/footerComp';
import LoadingIndicator from '../components/loading';
import { Index } from '../services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PolicySlider from '../components/policySlider';
import SEO from '../components/SEO';

const Contact = () => {
  
  const [loading, setIsLoaded] = useState(true);
  const [policy, setPolicy] = useState([]);
  const [contact, setContact] = useState({});

   
  const handleChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    const createUser = new Index();
      createUser.createContact(contact).then((response) => {
          console.log(response)
        response.accepted ?  toast.success("Message Sent"): toast.error("Error sending message")
      })
    }
  
 
  useEffect(() => {
    setIsLoaded(true);
    const newData = new Index();
    
    newData.findFPolicy().then((response) => {
      console.log(response)
      // if (response.statusCode == 200) {
      setPolicy(response);
      setIsLoaded(false);
        
      // }
    })
  }, []);

  return (

    <Box>
      
    {loading ?
         
          <LoadingIndicator />
        
      
    :
    <>
        <SEO
         title="Contact"
          description={`Ventures Platform Gallery, No 29 Mambilla Street, Maitama,Abuja FCT.
          +234 907 545 0934
          hello@apiintelligence.org.`}
keywords="Contact API, policy, legislation, white paper, regulatory, API Intelligence, advocacy, innovation, advocacy for policy and innovation"
></SEO>
      <Header currentPageUrl={window.location.pathname}></Header>
          <Center background=' linear-gradient(180deg, #233065 0%, #00061C 100%)'
              w={{ base: 'fit-content', md: 'fit-content', lg: 'fit-content', xl: 'auto' }} bgRepeat={'no-repeat'}
              height={600}>
            <Image src='/images/maskcontact.png' position={'absolute'} mt={180}></Image>
        <ToastContainer></ToastContainer>
        <Box px={{ base: 10, md: 150 }}>
          <Text color={'white'} w={{ base: "auto", md: "885px" }}
             fontFamily='Manrope' fontStyle='normal' fontWeight='800'
            fontSize={{ base: '30px', md: '48px' }} lineHeight={{ base: '45px', md: '55px' }} textAlign='center' 
            mt={20}          
            // border="1.09274px solid #1B1C57"
            >
            We've got your back
          </Text>
          
          <Text textAlign={'center'} color={'white'} 
        
            fontFamily='Lexend' fontStyle='normal' fontWeight='400' fontSize='20px'
            lineHeight='28.4px'
          >
      If you have any questions or need any support, <Hide below="md"><br /></Hide>please don’t hesitate to contact us.
        </Text>
                  <Center
                      mt={{ base: 380, md: 350 }}
                      
                  >
                <Flex  position='absolute'>
                <Card
                              background='#FFFFFF'
                              boxShadow='0px 3.53239px 36.207px rgba(0, 0, 0, 0.1)'
                              borderRadius='15.8957px'
                              p='5'
                              w={{ base: '100%', md: '496px' }}
                    >
                      <form onSubmit={handleSubmit}>
                          <CardBody py='10' px={{ base: '20px', md: '45px' }}>
                              <Text
                              fontFamily='Lexend'
                              fontStyle='normal'
                              fontWeight='600'
                              fontSize='18px'
                              lineHeight='18px'
                              color='#233065'
                              >Get in touch with Us</Text>
                              <Text
                                    fontFamily='Lexend'
                                     fontStyle='normal'
                                     fontWeight='400'
                                     fontSize='10px'
                                  color='#6B7280'
                            >If you have any questions or need any support,<br /> please don’t hesitate to contact us</Text>
                              <FormControl>
                                  <FormLabel>
                                      Name
                                  </FormLabel>
                                  <Input type="text" name="name" onChange={handleChange}></Input>
                              </FormControl>
                              <FormControl>
                                  <FormLabel>
                                      Email
                                  </FormLabel>
                                  <Input type="email" name="email" onChange={handleChange}></Input>
                              </FormControl>
                              <FormControl>
                                  <FormLabel>
                                      Phone Number
                                  </FormLabel>
                                  <Input type="number" name="phoneNumber" onChange={handleChange}></Input>
                              </FormControl>
                              <FormControl>
                                  <FormLabel>
                                      Company Name
                                  </FormLabel>
                                  <Input type="text" name="company" onChange={handleChange}></Input>
                              </FormControl>
                              <FormControl>
                                  <FormLabel>
                                      Message
                                  </FormLabel>
                                  <Textarea name="message" onChange={handleChange}></Textarea>
                                  </FormControl>
                                  <Button type='submit'
                                  display='flex'
                                  flexDirection='row'
                                  justifyContent='center'
                                  alignItems='center'
                                  padding='14.9978px 7.4989px'
                                  width='100%'
                                  height='42px'
                                  background='#233065'
                                      borderRadius='7.26072px'
                                    color={'white'}
                                  mt='5'    
                                  > Submit</Button>
                          </CardBody> 
                          </form>     
                          </Card> 
                </Flex>
            </Center>
        </Box>
      </Center>
      <Box px={{ base: 10, md: 150, '2xl': 400 }} mt={{ base: 380, md: 380}} w={{ base: 'fit-content', md: '1180px', lg:'1200px', xl:'auto' }}>
        <SimpleGrid columns={{ base: '1', md: '2' }} spacing='40px'>
   
          <Box>
            <Text fontFamily='Manrope' fontStyle='normal' fontWeight='700' fontSize='24px' lineHeight='36px'
              letterSpacing='-0.03em'
              color='#000000'>"Existing policy incentives are underutilized by digital entrepreneurs because of complex and burdensome requirements, confusing application processes, and a lack of transparency."
              </Text>
            <Text fontFamily='Manrope' fontStyle='normal' fontWeight='700' fontSize='18px' lineHeight='26px' letterSpacing='-0.02em'
              color='#000000' mt={10}>
              WORLD BANK GROUP
            </Text>
            <Text fontFamily='Manrope' fontStyle='normal' fontWeight='500' fontSize='16px' lineHeight='26px' letterSpacing='-0.02em'
              color='#64607D'>
              NIGERIA DIGITAL ECONOMY DIAGNOSTIC REPORT
            </Text>
              
          </Box>
          <Box>
            <Image src={'/images/quote.png'} w={{base:'full', '2xl': '70%'}}></Image>
          </Box>
          <Hide below='md'>
            <Image src='/images/right.png' position={'absolute'} right={0} marginTop={'-300px'}></Image>
          </Hide>
        </SimpleGrid>
      </Box>
      <Box background={'url(/images/bg-policy.png)'} height={{ base: 'auto', md: '736px' }}
      w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }} bgRepeat={'no-repeat'}
        backgroundSize={'cover'}>
        <Image src='/images/maskgroupblue.png' position={'absolute'} w={{ base: '100px', md: 'fit-content', lg: '1200px', xl: 'auto' }}></Image>
        <Box px={{ base: 10, md: 150, '2xl': 400}} my={{ base: 10, md: 180}} pb={5}>
          <br />
          <br />
          <Text
          fontFamily="Manrope"
          fontStyle="normal"
          fontWeight="800"
          fontSize={{ base: '35px', md: "40px" }}
          lineHeight={{ base: "48px", md:"66px" }}
          letterSpacing="-0.03em"
          color="#F7FA62"
          textAlign={'center'}
          >
            Our Policy Coverage</Text> 
          <Center>
          <Text
              fontFamily='Manrope'
              fontStyle='normal'
              fontWeight='500'
              fontSize='16px'
              lineHeight={{ base: '24px', md: '29px' }}
              textAlign='center'
              letterSpacing='-0.02em'
              color="white"
              w={{ base: '100%', md: '68%' }}
              mt={5}
          >At Advocacy for Policy and Innovation (API) we provide the latest and most accurate policy information and analysis targeted at technology-based investments, innovations and taxation on the following areas:</Text>
          </Center>
          
          <Center mt={20}> 
            <PolicySlider policy={policy}></PolicySlider>
      </Center>
      </Box>
      </Box>
     
      <Footer></Footer>
      </>}
    </Box>
    
    
  );
};

export default Contact;
