import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, Divider, UnorderedList, ListItem } from "@chakra-ui/react";
import Header from '../components/headerComponent';
import Footer from '../components/footerComp';
import LoadingIndicator from '../components/loading';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';

const PrivacyPolicyPage = () => {
  const [loading, setIsLoaded] = useState(true);

  useEffect(() => {
    setIsLoaded(false);
  }, []);
  
  return (
    <Box>
        {loading ?
         <LoadingIndicator />     
        :
        <>
         <SEO
         title="Privacy Policy"
          description={`This Privacy Policy was last updated on the 30th of June, 2023.
          While we have not made any material changes to the way we process data about our users, we have updated this policy to make it shorter and easier to read, and to comply with the Nigerian Data Protection Law and the EU General Data Protection Regulation.
          `}
          keywords="privacy, About API, policy, legislation, white paper, regulatory, API Intelligence, advocacy, innovation, advocacy for policy and innovation"></SEO>
     
      <Header currentPageUrl={window.location.pathname}></Header>
      <Box 
      my={{ base: 10, md: 150 }}
      px={{ base: 10, md: 10, lg: 150, '2xl': 400 }}
      w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
      >
      <Heading as="h1" fontSize="2xl" mb="4">
        Privacy Policy
      </Heading>
      
      <Text mb="4">
        This Privacy Policy was last updated on the 30th of June, 2023.
        While we have not made any material changes to the way we process data about our users, we have updated this policy to make it shorter and easier to read, and to comply with the Nigerian Data Protection Law and the EU General Data Protection Regulation.
        We at Advocacy for Policy and Innovation (API) respect your privacy and want you to understand how we collect, use, and share data about you. This Privacy Policy covers our data collection practices and describes your rights to access, correct, or restrict our use of your personal data.
        Unless we link to a different policy or state otherwise, this Privacy Policy applies when you visit or use the Company’s website, mobile applications, APIs or related services (the “Services”).
        By using the Services, you agree to the terms of this Privacy Policy. You shouldn’t use the Services if you don’t agree with this Privacy Policy or any other agreement that governs your use of the Services.
      </Text>
      
      <Divider mb="8" />
      
      <Heading as="h2" fontSize="xl" mb="4">
        Table of Contents
      </Heading>
      
      <UnorderedList ml="4" mb="8">
        <ListItem>
          <Text fontWeight="bold">Scope</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">Data We Collect from You</Text>
          <UnorderedList ml="4">
            <ListItem>Information You Provide to Us Directly</ListItem>
            <ListItem>Information We May Collect from Other Sources</ListItem>
            <ListItem>Information We May Collect When You Visit Our Websites</ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">How We Get Data About You</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">How We Use Your Data</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">How We Share Your Information</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">Our Use of Cookies</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">How We Protect and Retain Information</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">Your Rights as a Data Subject</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">Our Choices About Your Information</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">Cross-Border Data Transfers</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold">Updates and Modifications to This Privacy Policy</Text>
        </ListItem>
      </UnorderedList>
      
      <Divider mb="8" />
      
      <Heading as="h2" fontSize="xl" mb="4">
        Scope
      </Heading>
      
      <Text mb="4">
        This Privacy Policy (“Policy”) describes how we collect information when you use our website, services, tools and application program interface (API) (collectively, “Services”) or otherwise interact with us to operate, improve, develop, and protect our services, and as otherwise outlined in this Policy.
        We value transparency and want to provide you with a clear and concise description of how we treat your Private Information.
        We may check the details you provide with fraud prevention agencies and share your information with them if we suspect fraud. It is important that you do not provide false, inaccurate information or impersonate another individual.
        Please note that this Policy only covers Advocacy for Policy and Innovations (API). This Policy also does not cover any websites, products, or services provided by others. We encourage you to review the privacy policies or notices of developers or those third parties for information about their practices.
      </Text>
      
      <Heading as="h2" fontSize="xl" mb="4">
        Data We Collect from You
      </Heading>
      
      <Text mb="4">
        Personal information is data that can be used to identify or contact an individual.
        By using our Services, you consent to the storage, usage, and disclosure of your personal information, including your nonpublic personal information (collectively referred to as "Personal Information"). By accessing or using the Services or otherwise providing information to us, you consent to the control and processing of the information by Advocacy for Policy and Innovations (API).
        We collect certain data from you directly, like information you enter yourself, and data from third-party platforms you connect with the Company. We also collect some data automatically, like information about your device and what parts of our Services you interact with or spend time using. Any Personal Information we collect will be used strictly in accordance with current data protection legislation and this Privacy Statement.
      </Text>
      
      <Heading as="h3" fontSize="lg" mb="4">
        Information You Provide to Us Directly
      </Heading>
      
      <Text mb="4">
        We may collect different data from or about you depending on how you use the Services. Below are some examples to help you better understand the data we collect.
      </Text>
      
      <UnorderedList ml="4" mb="8">
        <ListItem>
          <Text>a. Account Data:</Text>
          <Text ml="4">
            In order to use certain features, you need to create a user account. When you create or update your account, we collect and store the data you provide, like your email address, password, gender, and assign you a unique identifying number ("Account Data").
          </Text>
        </ListItem>
        <ListItem>
          <Text>b. Unique Identifiable Data:</Text>
          <Text ml="4">
            You will need to provide your personal identifiable data such as driver’s license number, national identification number, international passport number, residence permit number, social security number, proprietary global unique identifiers.
          </Text>
        </ListItem>
        <ListItem>
          <Text>c. Employment Data:</Text>
          <Text ml="4">
            You will need to provide information on your employment status, employer details, business contact information, business registration details, proof of business activities.
          </Text>
        </ListItem>
        <ListItem>
          <Text>d. Profile Data:</Text>
          <Text ml="4">
            You can also choose to provide profile information like a photo, social media profiles, or other data. Your Profile Data will be publicly viewable by others.
          </Text>
        </ListItem>
        <ListItem>
          <Text>e. Payment Data:</Text>
          <Text ml="4">
            If you make purchases, we collect certain data about your purchase (such as your name and zip code) as necessary to process your order. You must provide certain payment and billing data directly to our payment processing partners, including your name, card information, billing address, and zip code. For security, the Company does not collect or store sensitive cardholder data, such as full credit card numbers or card authentication data.
          </Text>
        </ListItem>
        <ListItem>
          <Text>f. Data About Your Accounts on Other Services:</Text>
          <Text ml="4">
            We may obtain certain information through your social media or other online accounts if they are connected to your Company account. If you log in to Company via another third-party platform or service, we ask for your permission to access certain information about that other account. For example, depending on the platform or service, we may collect your name, profile picture, account ID number, login email address, location, and physical location of your access devices.
            Those platforms and services make information available to us through their APIs. The information we receive depends on what information you (via your privacy settings) or the platform or service decide to give us.
            If you access or use our Services through a third-party platform or service or click on any third-party links, the collection, use, and sharing of your data will also be subject to the privacy policies and other agreements of that third party.
          </Text>
        </ListItem>
        <ListItem>
          <Text>g. Communications and Support:</Text>
          <Text ml="4">
            If you contact us for support or to report a problem or concern (regardless of whether you have created an account), we collect and store your contact information, messages, and other data about you like your name, email address, location, operating system, IP address, and any other data you provide or that we collect through automated means (which we cover below). We use this data to respond to you and research your question or concern, in accordance with this Privacy Policy.
          </Text>
        </ListItem>
      </UnorderedList>
       <Heading as="h3" fontSize="lg" mb="4">
        Information We May Collect from Other Sources
      </Heading>
      
      <Text mb="4">
        When you access the Services, we collect certain data by automated means, including:
      </Text>
      
      <UnorderedList ml="4" mb="8">
        <ListItem>
          <Text>a. System Data:</Text>
          <Text ml="4">
            Technical data about your computer or device, like your IP address, device type, operating system type and version, unique device identifiers, browser, browser language, domain and other systems data, and platform types ("System Data").
          </Text>
        </ListItem>
        <ListItem>
          <Text>b. Usage Data:</Text>
          <Text ml="4">
            Usage statistics about your interactions with the Services, including features accessed, time spent on pages or the Service, pages visited, features used, your search queries, click data, date and time, and other data regarding your use of the Services ("Usage Data").
          </Text>
        </ListItem>
        <ListItem>
          <Text>c. Approximate Geographic Data:</Text>
          <Text ml="4">
            An approximate geographic location, including information like country, city, and geographic coordinates, calculated based on your IP address.
          </Text>
        </ListItem>
      </UnorderedList>
      
      <Heading as="h3" fontSize="lg" mb="4">
        Information We May Collect When You Visit Our Websites
      </Heading>
      
      <Text mb="4">
        For purposes of this Privacy Statement, "website" includes our mobile applications.
        By using our websites and associated microsites, you agree to the processing of your Personal Information as explained in this Privacy Statement, including the placement of cookies on your device.
        Personal information is collected to fulfill your requests for products and services and to improve your online experience. We take steps to limit the amount of Personal Information collected to what is required to support the intended purpose of the collection.
      </Text>
      
      <Text mb="4">
        API may ask you for some or all of the following types of information when you register for events, promotions, request services, manage accounts, access various content and features or directly visit our websites. The information includes, but is not limited to:
      </Text>
      
      <UnorderedList ml="4" mb="8">
        <ListItem>
          <Text>a. Contact Information:</Text>
          <Text ml="4">
            Name, Email Address, Postal Address, Phone Number, Mobile Number, Facsimile Number.
          </Text>
        </ListItem>
        <ListItem>
          <Text>b. Communication Preferences:</Text>
          <Text ml="4">
            Such as newsletters you would like to receive.
          </Text>
        </ListItem>
        <ListItem>
          <Text>c. Search Queries.</Text>
        </ListItem>
      </UnorderedList>
      
      <Text mb="4">
        In Some Instances,  API will automatically collect certain types of information when you visit our websites and through emails exchanged with API, or through our customer service chat. Automated technologies may include the use of web server logs to collect IP addresses, "cookies" and web beacons. The collection of this information will allow us to improve the effectiveness of our websites and our marketing activities.
      </Text>
      
      <UnorderedList ml="4" mb="8">
        <ListItem>
          <Text>1. Mobile Devices:</Text>
          <Text ml="4">
            If you access our websites on your mobile device, API may collect your unique device identifier and mobile device IP address, as well as information about your device's operating system, mobile carrier, and your location information.
          </Text>
          <Text ml="4">
            When you provide us your mobile phone number as your contact phone number, you consent to the use of your mobile phone number for the purposes identified in this Privacy Statement. If you choose to receive notifications from us on your mobile device (e.g., text notifications), you also consent to the use of your mobile phone number for that purpose.
          </Text>
        </ListItem>
        <ListItem>
          <Text>2. Location Information:</Text>
          <Text ml="4">
            We may obtain your location information from our Business Partners or otherwise on our Services. We may also incorporate geo-mapping into our Services, and you may consent to the collection of location information by the third-party geo-mapping service provider in connection with your use of the geo-mapping service. Any information collected via your use of the geo-mapping service should be transmitted directly through the geo-mapping service provider and is not through API. Please refer to the geo-mapping service provider's privacy policy for details about their collection, use, and sharing of this information.
          </Text>
        </ListItem>
        <ListItem>
          <Text>3. Links to Other Websites:</Text>
          <Text ml="4">
            Our websites may contain links to other sites which are outside our control and are not covered by this Privacy Statement. If you access other sites using the links provided, the operators of these sites may collect information from you which will be used by them in accordance with their privacy policies. API is not responsible for the content or privacy practices of linked sites or any use of those sites.
          </Text>
        </ListItem>
      </UnorderedList>
      
      <Text mb="4">
        Please be aware that API websites may contain links to sites maintained by other organizations. We encourage you to review the privacy policy of each website you visit.
      </Text>
      
      <Heading as="h3" fontSize="lg" mb="4">
        How We Get Data About You
      </Heading>
      
      <Text mb="4">
        1. Cookies and Similar Technologies
      </Text>
      
      <Text mb="4">
        API collects information during your visit to the website through the use of cookie technology. A cookie is a piece of programming information contained in a very small text file that is placed in your internet browser or elsewhere on your hard drive. You can control acceptance of cookies by modifying your internet browser preferences. You have the ability to accept all cookies, to be notified when a cookie is set, or to reject all cookies. Please note that if you choose to block all cookies (including essential cookies), you may not be able to access all or part of our site and may be unable to use those services or engage in activities that require the placement of cookies. API uses cookies to distinguish you from other users of our website and also provide us with aggregated website usage statistics.
      </Text>
      
      <Text mb="4">
        In addition, on some occasions, API may use tracking technologies such as web beacons to collect information about your visits to our websites. These are small electronic images embedded in web content or e-mail messages and are ordinarily not visible to users. Like cookies, this tracking technology enables us to track pages and content accessed and viewed by users on API websites. The use of these technologies also helps us to provide you with a positive experience when you browse our websites, and it also helps refine our content and personalize your experience.
      </Text>
      
      <Text mb="4">
        2. Information We Collect When You Engage With Us on Social Media
      </Text>
      
      <Text mb="4">
        You can engage with API through social media websites or applications or through features such as plug-ins or applications on our websites that integrate with social media sites. You may also choose to link your account with us to third-party social media sites. When you link your social media account or engage with API on or through third-party social media sites, plug-ins, or applications, you may allow API to have ongoing access to certain information from your social media account (e.g., name, e-mail address, photo, gender, birthday, the posts or the 'likes' you make).
      </Text>
      
      <Text mb="4">
        If you post information when you interact with our Services through social media sites, plug-ins, or other applications, depending on your privacy settings, this information may become public on the Internet. You can control what information you share through privacy settings available on some social media sites. For more information about how you can customize your privacy settings and how third-party social media sites handle your personally identifiable information, please refer to their privacy help guides, privacy policies, and terms of use.
      </Text>
      
      <Text mb="4">
        3. Information We May Collect Through Third-Party Advertising
      </Text>
      
      <Text mb="4">
        We may allow others to provide analytics services on our behalf and to serve advertisements on our behalf across the internet. These Third-Party Advertising Partners may use cookies, web beacons, and other technologies to display advertisements of our products to you when you are on other third-party websites that are affiliated in some way with the advertising partners. The information collected and shared via cookies may contain Personal Information.
      </Text>
      
      <Text mb="4">
        The Third-Party Advertising Partners may collect information about your use of the Services and other websites, including your IP address, web browser, pages viewed, time spent on pages, links clicked, and conversion information. This information may be used by API and others, to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising targeted to your interests, and better understand your online activity.
      </Text>
       <Heading as="h3" fontSize="lg" mb="4">
        How We Use Your Data
      </Heading>
      
      <Text mb="4">
        <strong>1. To Process Your Requests to Administer Your Account and Provide the Services to You</strong>
      </Text>
      
      <Text mb="4">
        Personal Information in our possession shall only be used in accordance with this Policy Statement, in performing statistical analysis of users’ behavior as a whole; and to help in the creation of anonymized statistical data which we may use at our discretion (including licensing to third parties).
      </Text>
      
      <Text mb="4">
        API collects this information in a transparent way and only with the full cooperation and knowledge of interested parties. Once this information is available to us, the following rules apply:
      </Text>
      
      <UnorderedList ml="4" mb="8">
        <ListItem>
          <Text>a. Accurate and kept up-to-date;</Text>
        </ListItem>
        <ListItem>
          <Text>b. Collected fairly and for lawful purposes only;</Text>
        </ListItem>
        <ListItem>
          <Text>c. Processed by API within its legal and moral boundaries; and</Text>
        </ListItem>
        <ListItem>
          <Text>d. Protected against any unauthorized or illegal access by internal or external parties.</Text>
        </ListItem>
      </UnorderedList>
      
      <Text mb="4">
        Our data will not be:
      </Text>
      
      <UnorderedList ml="4" mb="8">
        <ListItem>
          <Text>a. Communicated informally;</Text>
        </ListItem>
        <ListItem>
          <Text>b. Transferred to organizations, states, or countries that do not have adequate data protection policies; and</Text>
        </ListItem>
        <ListItem>
          <Text>c. Distributed to any party other than the ones agreed upon by the data’s owner (exempting legitimate requests from law enforcement authorities).</Text>
        </ListItem>
      </UnorderedList>
      
      <Text mb="4">
        API’s Business Partners are encouraged to:
      </Text>
      
      <UnorderedList ml="4" mb="8">
        <ListItem>
          <Text>a. Let people know which of their data is collected;</Text>
        </ListItem>
        <ListItem>
          <Text>b. Inform people about how API will process their data; and</Text>
        </ListItem>
        <ListItem>
          <Text>c. Inform people about who has access to their information.</Text>
        </ListItem>
      </UnorderedList>
      
      <Text mb="4">
        <strong>2. To Share Your Information</strong>
      </Text>
      
      <Text mb="4">
        We may disclose any information we collect as follows:
      </Text>
      
      <UnorderedList ml="4" mb="8">
        <ListItem>
          <Text>a. The Reporting Entity with whom you are establishing a relationship and other similar organizations, or those that are nominated by you;</Text>
        </ListItem>
        <ListItem>
          <Text>b. Government entities and other external data providers that hold information on you in order to verify that the information provided by you is consistent with the records held by such entities;</Text>
        </ListItem>
        <ListItem>
          <Text>c. To another user when you sign up for API’s services via a referral link. The user that referred you may receive information indicating that you have enrolled with API. You may avoid this sharing by not using a referral link to enroll;</Text>
        </ListItem>
        <ListItem>
          <Text>
            d. To Business Partners, affiliated companies, and authorized service providers, we disclose Personal Information as is necessary to provide our Services. These service providers are contractually restricted from using or disclosing the information except as necessary to perform services on our behalf or to comply with legal requirements. We do not rent, sell or otherwise disclose Personal Information about our online visitors with unaffiliated third parties for their own marketing use;
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            e. With other non-affiliated companies for our everyday business purposes, such as to maintain accounts, respond to court orders and legal investigations. For example, in connection with our everyday business purposes, we may share information about you as follows:
          </Text>
          <UnorderedList ml="4">
            <ListItem>
              <Text>i. In response to a request for information, if we are required by, or we believe disclosure is in accordance with, any applicable law, regulation or legal process;</Text>
            </ListItem>
            <ListItem>
              <Text>ii. With relevant law enforcement officials or other third parties, such as investigators or auditors, if we believe it is appropriate to investigate fraud;</Text>
            </ListItem>
            <ListItem>
              <Text>iii. If we believe your actions are inconsistent with the spirit or language of our user agreements or policies, or to protect the rights, property and safety of API, or others; and</Text>
            </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem>
          <Text>
            f. We may also share aggregated or de-identified Information, which cannot reasonably be used to identify you. For example, we may share transaction zip codes and merchant names with third parties to improve our accuracy in geo-locating transactions and to improve the clarity of transaction descriptions.
          </Text>
        </ListItem>
      </UnorderedList>
      <Heading as="h3" fontSize="lg" mb="4">
        How We Share Your Information
      </Heading>
      
      <Text mb="4">
        API takes reasonable steps to protect your Personal Information from misuse, loss, unauthorized access, modification, or disclosure.
      </Text>
      
      <Text mb="4">
        <strong>1. With our Affiliates</strong>
      </Text>
      
      <Text mb="4">
        We share your information with our affiliates to help us with technical processing operations, such as data hosting and maintenance, client care, marketing and targeted suggestions, better understanding how the Services are used and Users’ behavior to improve the Services, securing our data and systems, fighting against spam, abuse, fraud, and intellectual property infringement.
      </Text>
      
      <Text mb="4">
        <strong>2. When Required by Law</strong>
      </Text>
      
      <Text mb="4">
        Applicable law may require us and our service providers to disclose your information if:
      </Text>
      
      <UnorderedList ml="4" mb="8">
        <ListItem>
          <Text>a. reasonably necessary to comply with a legal process, such as a court order, subpoena, or search warrant, government investigation, or other legal requirements; or</Text>
        </ListItem>
        <ListItem>
          <Text>b. necessary for the prevention or detection of crime (subject in each case to applicable law).</Text>
        </ListItem>
      </UnorderedList>
      
      <Text mb="4">
        <strong>3. To Enforce Legal Rights</strong>
      </Text>
      
      <Text mb="4">
        We may also share information:
      </Text>
      
      <UnorderedList ml="4" mb="8">
        <ListItem>
          <Text>a. if disclosure would mitigate our liability in an actual or threatened lawsuit;</Text>
        </ListItem>
        <ListItem>
          <Text>b. as necessary to protect our legal rights and the legal rights of our users, business partners, or other interested parties;</Text>
        </ListItem>
        <ListItem>
          <Text>c. to enforce our agreements with you; and</Text>
        </ListItem>
        <ListItem>
          <Text>d. to investigate, prevent, or take other action regarding illegal activity, suspected fraud, or other wrongdoing.</Text>
        </ListItem>
      </UnorderedList>
      
      <Text mb="4">
        <strong>4. With Your Consent or at Your Request</strong>
      </Text>
      
      <Text mb="4">
        We may ask for your consent to share your information with third parties. When we do, we will make clear why we want to share the information.
      </Text>
      
      <Text mb="4">
        We may collect and provide aggregate information about our Users (such as how many persons visited a particular page or activity or the likes and dislikes of our Users) to our advertisers, sponsors, promotional partners, and affiliates, but this aggregated information does not include personal information about any individual User.
      </Text>
       <Heading as="h3" fontSize="lg" mb="4">
        Our Choices About Your Information
      </Heading>
      
      <Text mb="4">
        <strong>1. Information on API Database</strong>
      </Text>
      
      <Text mb="4">
        If you would like to review, correct, update, suppress, restrict, or delete personal information (including Personal Data as defined in the PRIVACY SHIELD section below) that you have previously provided to us, or if you would like to receive an electronic copy of your personal information for purposes of transmitting it to another company (if this right to data portability is provided to you by law), you can contact us at:
      </Text>
      
      <Text mb="4">
        Email:{" "}
        <Link href="mailto:contact@api.org" textDecoration="underline">
          contact@api.org
        </Link>{" "}
        or;
      </Text>
      
      <Text mb="4">
        Write to us at:
      </Text>
      
      <Text mb="4">
        Advocacy for Policy and Innovation Ltd/Gte<br />
        Attn: Privacy/Legal Team,<br />
        Address: No 5 Kwaji Close Maitama, Abuja, Nigeria.
      </Text>
      
      <Text mb="4">
        In your request, please make clear what personal information you would like to have changed, whether you would like to have your personal information suppressed from our database, or other limitations you would like to put on our use of your personal information. For your protection, we only fulfill requests for the personal information associated with the particular email address that you use to send us your request, and we may need to verify your identity before fulfilling your request. We will try to comply with your request as soon as reasonably practicable.
      </Text>
      
      <Text mb="4">
        Please note that we often need to retain certain data for recordkeeping purposes and/or to complete any transaction that you began prior to requesting a change or deletion. Our databases and other records may have residual data which will not be removed. In addition, we also may not allow you to review certain data for legal, security, or other reasons. If at any time you believe that the Services have not adhered to this Privacy Policy, please let us know. We will use good faith efforts to determine and correct the problem.
      </Text>
      
      <Text mb="4">
        <strong>2. Children’s Privacy</strong>
      </Text>
      
      <Text mb="4">
        API respects the privacy of your child, and we want to share with you our policies regarding children under the age of sixteen (16). Although Users of all ages may navigate through the Services, we do not knowingly collect personal information from anyone under age sixteen (16). If we learn that a child under age sixteen (16) has improperly provided us with information, we will notify the child’s parent or legal guardian and thereafter delete the child’s personal information from our records.
      </Text>
      
      <Text mb="4">
        <strong>3. Links to Other Websites And Services</strong>
      </Text>
      
      <Text mb="4">
        The Services may include links to third-party websites and services that are not operated by us. When you click these links, you will be directed away from the Services. A link to a third-party website or service does not mean that we endorse it or the quality or accuracy of information presented on it. If you decide to visit a third-party website or service, you are subject to its privacy practices and policies, not ours. API does not guarantee and is not responsible for the privacy or security of these third-party external websites, or for any information represented on these websites.
      </Text>
      
      <Text mb="4">
        <strong>CROSS-BORDER DATA TRANSFERS</strong>
      </Text>
      
      <Text mb="4">
        Advocacy for Policy and Innovation (API) is headquartered in Abuja, Nigeria. Our Data Privacy is governed by the Nigerian Data Protection Laws, European Union Data Protection Regulations, and the Data Protection Regulations of the jurisdictions in which our services are used. Sharing of information sometimes involves cross-border data transfers to or from Africa and other jurisdictions. By visiting or using our Services, you consent to the storage of your data on servers located in our host countries. We use the European Union Data Protection Privacy Shield and standard contractual clauses approved by our host countries to validate transfers of foreign residents’ personal information from and to various countries. For more information about our Privacy Shield certification, please visit our Privacy Shield policy.
      </Text>
      
      <Text mb="4">
        <strong>UPDATES AND MODIFICATIONS TO THIS PRIVACY POLICY</strong>
      </Text>
      
      <Text mb="4">
        From time to time, we may update this Privacy Policy. If we make any material changes to it, we will notify you via email, through a notification posted on the Services, or as required by applicable law. We will also include a summary of the key changes. Unless stated otherwise, modifications will become effective on the day they are posted.
      </Text>
      
      <Text mb="4">
        As permitted by applicable law, if you continue to use the Services after the effective date of any change, then your access and/or use will be deemed an acceptance of (and agreement to follow and be bound by) the revised Privacy Policy. The revised Privacy Policy supersedes all previous Privacy Policies.
      </Text>
      
      <Text mb="4">
        <strong>a. Interpretation</strong>
      </Text>
      
      <Text mb="4">
        Any capitalized terms not defined in this policy are defined as specified in API's Terms of Use. Any version of this Privacy Policy in a language other than English is provided for convenience. If there is any conflict with a non-English version, you agree that the English language version will control.
      </Text>
      
      <Text mb="4">
        <strong>b. Contact Us</strong>
      </Text>
      
      <Text mb="4">
        If you have any questions, concerns, or disputes regarding our Privacy Policy, please feel free to contact our privacy team (including our designated personal information protection manager) at:
      </Text>
      
      <Text mb="4">
        Email:{" "}
        <Link href="mailto:contact@api.org" textDecoration="underline">
          contact@api.org
        </Link>{" "}
        or;
      </Text>
      
      <Text mb="4">
        Write to us at:
      </Text>
      
      <Text mb="4">
        Advocacy for Policy and Innovation<br />
        Attn: Privacy/Legal Team,<br />
        Address: No.5 Kwaji Close Maitama Abuja, Nigeria.
      </Text>
      
    </Box>
    <Footer></Footer>
    </>}
    </Box>
  );
};

export default PrivacyPolicyPage;
