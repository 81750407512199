import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from '../auth/auth'
import {
  FormControl,
  FormLabel,
  Stack,
  Flex,
  Text,
  Center,
  Input,
  Button,
  InputGroup,
  Image,
  CircularProgress,
  InputLeftElement
} from '@chakra-ui/react';
import { Index } from '../services/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEnvelope } from 'react-icons/fa';
import GoBackButton from '../components/backIcon';


const Login = () => {
  let navigate = useNavigate();
  const location = useLocation();
  let auth = useAuth();  
 

    const [user, setUser] = useState({
        username: '', password: ''
      });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevState => ({
          ...prevState,
          [name]: value,
        }));
    };
    
  const handleSubmit = (event) => {
      
    document.querySelector("#loader").style = "display:block";
        event.preventDefault();
        const index = new Index();
        index.login(user).then(
          async (res) => {
            let response = await res;
            if (response.data.statusCode || response.data.status === 401) {
                toast.error(response.data.message)
              document.querySelector("#loader").style = "display:none";
             
            } else if (response.data.id) {
              console.log(response.data)
              auth.login(response.data)
              toast.success("Login Successful");
              const redirectPath = window.history.length > 1 ? -1 : "/resources";
              setTimeout(() => navigate(redirectPath, { replace: true }), 2000)
            }
          }
        );
      };
  return (
    <Flex h={'100vh'}>
    <Flex h={'100vh'} w="100%" direction={"column"}>
      <Flex h="inherit" w="100%">
          <Flex h="100%" w={{ base: "100%", md: "55%" }} px={{base: 5, md: 120}}  direction={"column"}>
            <Stack>
              <Link to={'/'} >
            <Image src="/logo/Logo.svg" w={250} mt={5} ml={"-50px"}></Image>
            </Link>
            </Stack>
            <Stack mt={"-2"} padding="16.4976px 23.9965px;" gap="5px" width={{ base: "80%", md: "100%" }}>
              <ToastContainer></ToastContainer>
              <GoBackButton url={'home'}></GoBackButton>
            <Text fontWeight={500} fontFamily='Inter' fontSize={18} color={'#23262F'}>Access Your API Account</Text>          
            <form onSubmit={handleSubmit}>
            <FormControl>
                  <CircularProgress isIndeterminate id={"loader"}  maxH="10px" display={'none'} ></CircularProgress>  
            </FormControl>
            <FormControl>
              <FormLabel color={'#87898E'} fontStyle="normal" fontWeight="500" fontSize="14px" fontFamily='Manrope'>
                Email
              </FormLabel>
    
              <InputGroup>
                <InputLeftElement
                  pointerEvents='none'
                  children={<FaEnvelope />}
                />
                    <Input type='text' name="username" background="#FCFCFD" border="1.49978px solid #DFDFE6"
                      borderRadius="8.99867px" onChange={handleChange}/>
              </InputGroup>
              
            </FormControl>

            <FormControl>
              <FormLabel color={'#87898E'} fontStyle="normal" fontWeight="500" fontSize="14px" fontFamily='Manrope'>
                Password
              </FormLabel>
                  <Input type={'password'} name="password" background="#FCFCFD" border="1.49978px solid #DFDFE6"
                    borderRadius="8.99867px" onChange={handleChange}></Input>
              </FormControl>
              <br/>
              
                <Button 
                  type='submit'
                  mt={5}
                  background="#233065"
                  borderRadius="7.26072px"  
                  color={'white'}
                  flex-direction="row"
                  justifyContent="center"
                  alignItems="center"
                  padding="14.9978px 7.4989px"
                  w={'100%'}
                  flexGrow="0;">
                  Login</Button>
                
              </form>
              <Center>
              <Text fontSize={14} fontFamily={'Inter'}>
                  Don't have an account?  <Link to="/register" color='#374151' fontWeight={700} ml={100}>   Sign Up </Link>
                  </Text>
            </Center>
          </Stack>
              
            </Flex>
          <Flex h="100%" w="45%" bg="url(/images/bg-auth.png)" display={{ base: "none", md:"flex"}} >
            
            </Flex>
      </Flex>
      </Flex>
  </Flex>
  );
}


export default Login;