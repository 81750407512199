import React, { useState, useEffect } from 'react';
import {
  Box, SimpleGrid, Image,
  Text, Button
} from '@chakra-ui/react';
import Header from '../components/headerComponent';
import Footer from '../components/footerComp';
import LoadingIndicator from '../components/loading';
import { Index } from '../services';
import { useAuth } from '../auth/auth';
import { Link } from 'react-router-dom';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useParams } from 'react-router-dom';

const Detail = () => {
  let auth = useAuth();
  const { id } = useParams();
  const [loading, setIsLoaded] = useState(true);
  const [policy, setPolicy] = useState([]);
  useEffect(() => {
    setIsLoaded(true);
    const newData = new Index();
    newData.findDetail(window.location.pathname.split("/")[2], window.location.pathname.split("/")[1]).then((response) => {
      console.log(response)
      if (response.statusCode == 200) {
        setPolicy(response.data);
        setIsLoaded(false);
        
      }
    })
  }, [id]);
  if (!policy) {
    return  <LoadingIndicator />;
  }

  return (

    <Box>
      
    {loading ?
         
          <LoadingIndicator />
        
      
    :
    <>      <Header currentPageUrl={window.location.pathname}></Header>
       
      <Box px={{ base: 10, md: 150, '2xl': 400  }} mt={{ base: 20, md: 20}} w={{ base: 'fit-content', md: '1180px', lg:'1200px', xl:'auto' }}>
        <SimpleGrid columns={{ base: '1', md: '2' }} spacing='40px'>
          <Box border={'1px solid grey'} borderRadius={'20px'} height={"350px"}>
          
            <Image src={`${process.env.REACT_APP_API_URL}/upload/${policy.picture || '1deacd835812ab7391247a14d0caab96.png'}`} w={'100%'} h={'100%'} borderRadius={'20px'}></Image>
          </Box>
          <Box>
                <Text fontFamily='Manrope' fontStyle='normal' fontWeight="800"
                      fontSize="32px" lineHeight="56px"  letterSpacing='-0.03em'
              color='#000000'>{policy.title}
              </Text>
            <Text fontFamily='Manrope' fontStyle='normal' fontWeight='500' fontSize='16px' lineHeight='26px' letterSpacing='-0.02em'
                          color='#000000' mt={10} dangerouslySetInnerHTML={{ __html: policy.description }}>
             
            </Text>
            <Box mb={10} display={'flex'}>
          <a href={`${process.env.REACT_APP_API_URL}/upload/${policy.summary}`}
          target="_blank" rel="noopener noreferrer" mx={1}>        <Button color={'#fff'} bg={'#233065;'}
                mt={10}
                fontSize={'12px'}
                p={'12px 24px'}
              height={'54px'}
              w={200}
                textAlign={'center'}
                alignSelf={'center'}
                borderRadius='12px'
            > VIEW SUMMARY </Button>
            </a>
 
         {/* <Text fontWeight={700} mb={5} mt={10}>
            Preview Summary
          </Text>
          <PDFPreview 
      
          zIndex={99999}
          url={`${process.env.REACT_APP_API_URL}/upload/${policy.summary}`}></PDFPreview> 
        */}
            {auth.user ?
            <a href={`${process.env.REACT_APP_API_URL}/upload/${policy.documentPolicy}`}  target="_blank"
               rel="noopener noreferrer">
            <Button color={'#fff'} bg={'#233065;'}
                mt={10}
                mx={1}
                fontSize={'12px'}
                p={'12px 24px'}
              height={'54px'}
              w={"100%"}
                textAlign={'center'}
                alignSelf={'center'}
                borderRadius='12px'
            > DOWNLOAD PDF </Button> 
              </a> :  <Link to="/login">
            <Button color={'#fff'} bg={'#233065;'}
                mt={10}
                mx={2}
                fontSize={'12px'}
                p={'12px 24px'}
              height={'54px'}
              w={240}
                textAlign={'center'}
                alignSelf={'center'}
                borderRadius='12px'
            > LOGIN TO DOWNLOAD  </Button> 
              </Link>}
          </Box>
          </Box>

          
        </SimpleGrid>
        
      </Box>

      <Footer></Footer>
      </>}
    </Box>
    
    
  );
};

export default Detail;
