import React from 'react';
import { Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import SquareCard from './SquareCard'; // Make sure to import the SquareCard component

const PolicySlider = ({ policy }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
         {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box w={"100%"}>
      <Slider {...settings} w={"100%"}>
        {policy &&
          policy.map((pol) => (
            <div key={pol._id} >
              <SquareCard

        
                title={pol.title}
                imageUrl={`${process.env.REACT_APP_API_URL}/upload/${pol.picture || '1deacd835812ab7391247a14d0caab96.png'}`}
                link={`/resource/${pol._id}`}
              />
            </div>
          ))}
      </Slider>
    </Box>
  );
};

export default PolicySlider;
