import React, { useState, useEffect } from 'react';
import {
  Box, SimpleGrid, Image, Center,
  Text, Hide, Button
} from '@chakra-ui/react';
import Header from '../components/headerComponent';
import Footer from '../components/footerComp';
import LoadingIndicator from '../components/loading';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';

const About = () => {
  const [loading, setIsLoaded] = useState(true);

  useEffect(() => {
    setIsLoaded(false);
  }, []);
  
  return (
    <Box>
        {loading ?
         <LoadingIndicator />     
        :
        <>
         <SEO
         title="About"
          description={`API bridges the communication asymmetry between policymakers and relevant stakeholders,
particularly within the innovation ecosystem in Africa. API tracks, analyzes and identifies policies
right from nascent stages of development and provides a feedback loop for stakeholders.

We support policy and compliance teams to think and react to government policy and provide
intelligence to support comprehensive understanding of policy issues.`}
keywords="About API, policy, legislation, white paper, regulatory, API Intelligence, advocacy, innovation, advocacy for policy and innovation"
></SEO>
     
      <Header currentPageUrl={window.location.pathname}></Header>
      <Center
        position={'relative'}
        w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
        bgRepeat={'no-repeat'}
        backgroundSize={'cover'} height={600}>
           <Box
          position={'absolute'}
          zIndex={'888'}
          opacity="0.7"
          height={600}
          backgroundSize={'cover'} 
          w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
          bgRepeat={'no-repeat'}
         background="linear-gradient(180deg, #233065 0%, #00061C 100%)"></Box>
        <video 
          style={{ height: 600, width: '100%', objectFit: 'cover', objectPosition: 'center' }} autoPlay loop>
          <source src='/videos/about.mp4' type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        
        <Box 
        // px={{ base: 10, md: 250 }}
        position='absolute'
        zIndex={'9999'}
        >
          <Text color={'white'} w={{ base: "auto", md: "885px" }}
            height={{ base: "auto", md: "113px" }} fontFamily='Manrope'
            fontStyle='normal' fontWeight='800' fontSize={{ base: '28px', md: '36px' }}
            lineHeight={{ base: '30px', md: '49px' }} textAlign='center' 
                  >
                A platform to curate and aggregate feedback for 
             <Hide below='md'><br/></Hide> policymakers and stakeholders
          </Text>
          
          <Text px={5} textAlign={'center'} color={'white'} mt={{ base: 3, md: 30 }}
            height={{ base: 'auto', md: '84px' }}         
            fontFamily='Lexend' fontStyle='normal' fontWeight='400' fontSize='14px'
            lineHeight='28px'
          >
            API bridges the communication asymmetry between policymakers and relevant stakeholders,<Hide below='md'><br /></Hide>
            particularly within the innovation ecosystem in Africa. API tracks, analyzes and identifies policies <Hide below='md'><br /></Hide>
            right from nascent stages of development and provides a feedback loop for stakeholders. 
         </Text>
        <Text textAlign={'center'} color={'white'} mt={{ base: 3, md: 30 }}
            height={{ base: 'auto', md: '84px' }}         
            fontFamily='Lexend' fontStyle='normal' fontWeight='400' fontSize='16px'
            lineHeight='28px'
          >

            We support policy and compliance teams to think and react to government policy and provide <Hide below='md'><br/></Hide> intelligence to support comprehensive understanding of policy issues.
        </Text>
          <Center>
          <Link to={'/resource'}>
          <Button bg={'#EDF484'} color={'#233065;'}
              mt={10}
              fontSize={'16px'}
            p={'12px 24px'}
            width={'180px'} height={'54px'}
            textAlign={'center'}l
              alignSelf={'center'}
              borderRadius='12px'
              fontFamily='Manrope'
              fontStyle='normal'
              fontWeight='700'
              
              > VIEW RESOURCES</Button>  
              </Link>
            </Center>
        </Box>
      </Center>
      <Box 
      my={{ base: 10, md: 150 }}
      w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
      >
        
        <SimpleGrid 
        px={{ base: 10, md: 10, lg: 150, '2xl': 400 }}
         columns={{ base: '1', md: '2' }} spacing={{ base: '40px', md: '40px' }}
        w={{ base: 'fit-content', md: '1080px', lg:'fit-content' }}
        >
          <Box>
            <Image width={{ base: 'auto', md: 'auto' }}
              height={{ base: 'auto', md: 'auto' }}
            src={'/images/mission.png'}></Image>
          </Box>
          <Box w={'100%'}>
            <Text
              fontFamily='Manrope'
              fontStyle='normal'
              fontWeight='800'
              fontSize={{ base: '35px', md: '43px' }}
              lineHeight={{ base: '40px', md: '56px' }}
              letterSpacing='-0.03em'
              color='#000000'
              mt={15}
            >Our Mission</Text>
            <Text fontFamily='Manrope' fontStyle='normal' fontWeight='500'
              fontSize='16px' lineHeight='26px'
              letterSpacing='-0.02em'
              color='#64607D'
              mt={5}
            >
              The mission of API is to provide policy intelligence and services, designed to mitigate the risk arising from the development and implementation of policy and regulations, that affect the innovation ecosystem and investors.</Text>

            <Text fontFamily='Manrope' fontStyle='normal' fontWeight='500'
                fontSize='16px' lineHeight='26px'
                letterSpacing='-0.02em'
                color='#64607D'
                mt={10}
            >
                          We work to proffer recommendations for possible situational interventions,  aiding stakeholders to meaningfully engage and substantially mitigate policy and regulatory risks.
            </Text>
            <Text fontFamily='Manrope' fontStyle='normal' fontWeight='500'
              fontSize='16px' lineHeight='26px'
              letterSpacing='-0.02em'
              color='#64607D'
              mt={10}
            >
                We are recognized for providing real-time, verifiable and valuable policy intelligence to our existing clients.
            </Text>
            <Link to={'/contact'}>
              <Button color={'#fff'} bg={'#233065;'}
              mt={10}
              zIndex={99999}
              fontSize={'12px'}
                p={'12px 24px'}
                height={'54px'}
                textAlign={'center'}
                  alignSelf={'center'}
                  borderRadius='12px'
            > REQUEST FOR MORE INFORMATION </Button>  
            </Link>
          </Box>
        </SimpleGrid>
        <Image src={'/images/maskgroupwhite.png'} position={'absolute'} marginTop={'-390px'} w={{ base: '100px', md: 'fit-content', lg: '1200px', xl: 'auto' }}></Image>
      </Box>
    <Box px={{ base: 10, md: 150, '2xl': 400 }} my={{ base: 1, md: 180 }}
              w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
              height={'full'} justifyContent={'center'}>
       
        <Center py={5}
          
              width='auto'
              height='312px'
              left='0px'
              top='0px'
              mt={100}
              background='radial-gradient(50% 50% at 50% 50%, #233065 0%, #000000 100%)'
              borderRadius='32px'
              >
          
        <Box p={'10'}>
          <Text color={'#FFF'} lineHeight={'48px'} fontSize={'32px'} fontWeight={'600'} fontFamily={'Lexend'} textAlign={'center'}>
            Our Team
            </Text>
            <Center>
            <Text
            fontFamily='Public Sans'
            fontStyle='normal'
            fontWeight='400'
            fontSize='16px'
            lineHeight='24px'
            textAlign='center'
            letterSpacing='-0.02em'
            color="#FFF"
            w='58%'
              >API Founders have firsthand experience and insights having worked on different sides of the policy frontline.</Text>
              </Center>
            </Box>
            <Hide below='md'>
          <Image src='/images/right.png' position={'absolute'} right={0} marginTop={'-290px'}></Image>
          </Hide>
      </Center>
      </Box>
      <Box  my={{ base: 10, md: 150 }} w={{ base: 'fit-content', md: 'fit-content', lg: '1200px', xl: 'auto' }}>
        
        <SimpleGrid px={{ base: 10, md: 10, lg: 150, '2xl': 400 }} columns={{ base: '1', md: '2' }} spacing={{ base: '40px', md: '40px' }}
        w={{ base: 'fit-content', md: '1080px', lg:'fit-content' }}
        >
          <Box>
            <Image width={{ base: 'auto', md: 'auto' }}
              height={{ base: 'auto', md: 'auto' }}
            src={'/images/client.png'}></Image>
          </Box>
          <Box w={'100%'}>
            <Text
              fontFamily='Manrope'
              fontStyle='normal'
              fontWeight='800'
              fontSize={{ base: '35px', md: '32px' }}
              lineHeight={{ base: '36px', md: '41.6px' }}
              letterSpacing='-0.03em'
              color='#000000'
              mt={120}
            >Investment Managers, Legal & Compliance Managers, Policy Teams</Text>
            <Text fontFamily='Manrope' fontStyle='normal' fontWeight='500'
              fontSize='16px' lineHeight='26px'
              letterSpacing='-0.02em'
              color='#64607D'
              mt={5}
            >
                          We help Investment Managers, Compliance managers within the African Start-up and Innovation Ecosystem to distill complex policy issues, determine their impact on business, develop mitigation strategies and communicate the same to key stakeholders.
                      </Text>

              <Link to={'/contact'}>
              <Button color={'#fff'} bg={'#233065;'}
              mt={10}
              zIndex={99999}
              fontSize={'12px'}
                p={'12px 24px'}
                height={'54px'}
                textAlign={'center'}
                  alignSelf={'center'}
                  borderRadius='12px'
            > REQUEST FOR MORE INFORMATION </Button>  
            </Link>
          </Box>
        </SimpleGrid>
        <Image src={'/images/maskgroupwhite.png'} position={'absolute'} marginTop={'-390px'} w={{ base: '100px', md: 'fit-content', lg: '1200px', xl: 'auto' }}></Image>
      </Box>

      <Footer></Footer>
      </>}
    </Box>
    
    
  );
};

export default About;
