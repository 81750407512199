import React, { useState, useEffect } from 'react';
import {
  Box, OrderedList,
  Text, ListItem
} from '@chakra-ui/react';
import Header from '../components/headerComponent';
import Footer from '../components/footerComp';

const Condition = () => {

  return (

    <Box>
      
      <Header currentPageUrl={window.location.pathname}></Header>
       
      <Box px={{ base: 10, md: 150 }} mt={{ base: 20, md: 20}} 
      w={{ base: 'fit-content', md: '1180px', lg:'1200px', xl:'auto' }}
      fontFamily='Manrope'
      >
        <Text
        fontFamily='Manrope'
        fontStyle="normal"
        fontWeight="700"
        fontSize="32px"
        lineHeight="130.19%"
        color="#233065">
            API Condition of Service
        </Text>
        <Text
        fontFamily='Manrope'
        fontStyle="normal"
        lineHeight="130.19%"
        color="#233065">
            Last updated: February 12, 2021 
        </Text>
        <Text mt={3}>
      Thank you for subscribing to our Policy Intelligence service (hereinafter referred to as “service” or ”services” as the context may demand). This service is offered by API (“we”, “us”) and by accepting this Agreement, you agree to be bound by all of the terms and conditions detailed below.</Text>
      <OrderedList>
        <ListItem mt={5}>
       <Text color="#545964" fontSize={18} fontFamily={700}> Definitions </Text>
        <Text>APi (“we”, “us” or “our”) is a technology platform that provide digital, financial and payment infrastructure systems to large and micro enterprises “merchants” make it easy for merchants to use the platform for financial services distribution and to accept/make payments online from users or customers.
            </Text>
        </ListItem>

        <ListItem mt={5}> 
        <Text color="#545964" fontSize={18} fontFamily={700}> Service Provided</Text>
<Text>2.1 For the purpose of this agreement, this service is broadly described as policy intelligence. It involves tracking and providing premium information on the latest policy developments in Nigeria upon your request for subscription.
</Text><Text>2.2 Components of this service may include but are not limited to a real-time regulatory tracker and interactive repository, compliance checker and support, impact research, engagement with stakeholders as well as open resources.
</Text><Text>2.3 All of the components of this service are covered by this agreement. That is, every information received by you from us under this service is subject to this agreement.
</Text><Text>2.4 This service is available strictly for clients with valid subscriptions.
</Text></ListItem>

<ListItem mt={5}>
<Text color="#545964" fontSize={18} fontFamily={700}> Access To and Use of Services</Text>
<Text>3.1 Subject to your compliance with the terms of this agreement, we hereby grant you a limited, personal, and non-transferable right to access the features and function of this Service during the period of your subscription. Access granted may not be sublicensed in whole or in part and the scope of your use of this service is subject to this agreement.
</Text><Text>3.2 We may provide you with the necessary credentials and protocols to access this service but you shall be responsible for all acts and omissions in breach of this agreement (whether performed by an unauthorized or authorized user) using your access protocols.
</Text><Text>3.3 By subscribing, You agree to take sole responsibility for the confidentiality, use of and access to API Content using your subscription information. You agree to immediately notify API of any reasonable suspicion of a compromise involving your password, user ID or e-mail address and or any API Content. You will provide API with accurate, complete registration information and inform API of any changes thereof. For the purpose of confirming user compliance with these Terms of services, API reserves the right to monitor and record activity on the Site and access to its content.
</Text>
</ListItem>
<ListItem mt={5}>
<Text color="#545964" fontSize={18} fontFamily={700}> Prohibited Uses</Text>
<Text>4.1 You may not use, promote, facilitate or encourage the use of this service and information or intelligence derived from this service for any activity that violates any law, statute, ordinance, or regulation or for any other illegal, fraudulent or harmful purpose.
</Text><Text>4.2 You may not also use this service or the information derived from this service in any way that violates the intellectual property rights, such as and including copyrights of our organization or any other person.
</Text><Text>4.3 You may not use this service or the information derived from this service in the creation, publication or distribution of ‘fake news’, ‘hoax news’ or similar false content purposefully intended to mislead or deceive others.
</Text><Text>4.4 You may not use this service or the information derived from this service directly or indirectly in furtherance of any act of terrorism or public disturbance.
</Text></ListItem>
<ListItem mt={5}>
<Text color="#545964" fontSize={18} fontFamily={700}> Intellectual Property and Proprietary Rights</Text>
<Text>5.1 All intellectual property and service content are and shall remain owned by us and are protected by copyright, trademark, patent, trade secret and other laws and treaties. All feedback given to us constitutes the benefit of interaction and we’re at liberty to communicate or use same at our discretion.
</Text><Text>5.2 Your feedback and suggestions on service delivery and improvements on the general service package are very important to us and as such, we may seek the same from you. If we receive any suggestion, ideas, improvements, modifications, feedback, error identifications of other information related to this service or any other information related to this service, we may use, disclose and exploit such Feedback without restriction and without paying any royalties or compensation including to take any action to improve this service.
</Text></ListItem>
<ListItem mt={5}>
<Text color="#545964" fontSize={18} fontFamily={700}> Obligation of Confidentiality</Text>
<Text>6.1 All activities of the parties under this agreement are subject to the following confidentiality terms:
That all information and service rendered as service are rendered only to the holder of the account to which such information is sent and that you (being the account holder) are prohibited from sharing such information with any third party apart from agents, employees or other representatives.
That no party will not use, or disclose to any third party, any other Confidential Information disclosed to it by the other party for any purpose except as expressly permitted in this Agreement,
That all parties shall protect such Confidential Information from disclosure to others, using the same degree of care used to protect its own confidential or proprietary information of like importance, but in any case using no less than a reasonable degree of care.
</Text><Text>
6.2 Notwithstanding the foregoing, each party may disclose Confidential Information
to its employees, directors, affiliates, advisors, agents, independent contractors and consultants ("Representatives") on a need-to-know basis, provided that the receiving party has enforceable agreements or policies with each of such Representatives sufficient to enable compliance by the receiving party with all the provisions of this Agreement and requiring such Representative, for the benefit of the disclosing party, to maintain the confidence of the disclosing party's Confidential Information as provided in this Agreement, or
in accordance with a judicial or other governmental order or request, provided the receiving party shall, if permitted by law, give the disclosing party reasonable opportunity to seek a protective order, or obtain written assurance from the applicable judicial or governmental entity that will afford the Confidential Information of the other party the highest level of protection afforded under applicable law or regulation.
</Text><Text>6.3 The receiving party shall be responsible for any failure by any of its Representatives to comply with any of the terms of this Agreement, and the receiving party agrees, at its sole expense, to restrain its Representatives from the disclosure or use of the disclosing party's Confidential Information to the extent prohibited or unauthorized by this Agreement. Neither Party may use the name of the other in any published advertising or publicity materials without the prior written consent of the other party.
</Text>
</ListItem>
<ListItem mt={5}>
<Text color="#545964" fontSize={18} fontFamily={700}> Limitation of Liability</Text>
<Text>7.1 Unless otherwise expressed in this agreement, neither party shall have any liability under or in connection with this Agreement for any indirect, incidental, consequential, special, exemplary or punitive damages, nor any liability for lost profits, loss of data, loss of business opportunity, or business interruption, regardless of the theory of liability (including theories of contractual liability, tort liability, or strict liability), even if the liable party knew or should have known that those kinds of damages were possible.
</Text><Text>7.2 You are responsible for your compliance with any and all applicable laws and regulations. Any content provided by API is not intended, and should not be taken, as legal advice.
</Text></ListItem>
<ListItem mt={5}>
<Text color="#545964" fontSize={18} fontFamily={700}> Subscription and Cancellation</Text>
<Text>8.1. REGISTRATION: API provides a portal for visitors to register/open a personal account on our platform. However, mere registration does not signify a contract with API ab initio. To enter a contractual relationship with us, there must be a signed contract and verification from us. API reserves the right to refuse a clients registration including suspending or blocking your access to the Site.
</Text><Text>8.2 SUBSCRIPTION: API offers a Subscription package. API reserves the right to vary the amount of content and types of service that it makes available to different categories of users at any time, including without limitation to Registered Users.API will try to process your Subscription within reasonable time but does not guarantee that your Subscription will be activated by any specified time. By submitting your payment and other subscription details, you are making an offer to us to buy a Subscription. Your offer will only be accepted by us and a contract formed when we have successfully verified your payment and contact details, at which point we will provide you with access to the Subscription. You will receive written confirmation when your Subscription offer has been accepted.
</Text><Text>8.3. RENEWAL: Please note that unless specifically indicated in any of the terms/ Conditions of service, when you place your Subscription order with API, if you select an auto-renewal payment option, including direct debit to your bank account, you agree that at the end of each renewal period thereafter, your Subscription will automatically renew for the same subscription period at the then prevailing renewal rate, which may be changed from time to time. In the event that you do not choose an auto-renew option, we will contact you at the end of each subscription period with an offer to renew your subscription at the then prevailing renewal rate, which may be changed from time to time. You may cancel your Subscription at any time having in mind that we will charge for services provided employing our prorate formula.
</Text><Text>8.4. CANCELLATION: You may cancel your subscription at any time by clicking on the cancellation or unsubscribe link provided on your account’s profile/dashboard. You may also cancel your subscription for by reaching out to our admin via subscription@apiintelligence.org
</Text><Text>8.5. CONTRACT AND DISPUTES: This agreement is deemed as a contract and any dispute, controversy or claim arising out of or in relation to this agreement, including any question regarding its breach, existence, validity or termination or the legal relationships established by this agreement, shall be finally resolved by arbitration under the LACIAC Arbitration Rules, which Rules are deemed to be incorporated by reference into this clause.
</Text><Text>8.6. FEES AND PAYMENTS: Subscription fees and payments shall be as prescribed at the point of subscription and we reserve the right to amend the fees and mode of payment from time to time.
</Text><Text>8.6.1. REFUNDS: We neither offer full or pro-rated refunds for cancelled subscriptions. If however, the Service(s) have not been accessed by you, then we will offer the refund of the subscription minus the cost of the transaction. If you wish to process a refund, please contact …..
</Text></ListItem>
<ListItem mt={5}>
<Text color="#545964" fontSize={18} fontFamily={700}> Governing Law</Text>
<Text>9.1 The validity, construction, and interpretation of this Agreement and the rights and duties of the Parties shall be governed by the laws of the state of Federal Republic of Nigeria without regard to principles of conflicts of laws.
</Text></ListItem>
<ListItem mt={5}>
<Text color="#545964" fontSize={18} fontFamily={700}> Changes to this Agreement </Text>
    <Text>API reserves the right, in its sole discretion, to modify, alter, or otherwise change this Agreement at any time. We will provide notice of such change on the Website. Please review the Terms of Use and/or Additional Terms periodically for changes. Your continued use of API Website constitutes your acceptance of and agreement to be bound by these changes without limitation, qualification or change. If at any time you determine that you do not accept these changes, you must stop using this Website.
</Text></ListItem>
<ListItem mt={5}>
<Text color="#545964" fontSize={18} fontFamily={700}> Privacy </Text>
<Text>API's Privacy Policy applies to your use of this Site and is part of these Terms. Please review API Privacy Policy to get information on API’s use of your information
</Text></ListItem>
</OrderedList>
      </Box>

      <Footer></Footer>
    </Box>
    
    
  );
};

export default Condition;
