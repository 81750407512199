import React from 'react';
import { 
    Routes,
    Route } from 'react-router-dom';
import Login from '../pages/SignIn';
import HomePage from '../pages/HomePage';
import About from '../pages/About';
import { AuthProvider } from '../auth/auth';
import Register  from '../pages/SignUp';
import Contact from '../pages/Contact';
import Resources from '../pages/Resources';
import Detail from '../pages/Detail';
import More from '../pages/More';
import Condition from '../pages/Condition';
import PrivacyPolicyPage from '../pages/PrivacyPolicy';
import PrivacyShield from '../pages/PrivacyShield';

const AppRoute = () => (
    
        <AuthProvider>
        <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/about" exact element={<About />} />
            <Route path="/condition" exact element={<Condition />} />
            <Route path="/resource" exact element={<Resources />} />
            <Route path="/contact" exact element={<Contact />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/register" exact element={<Register />} />
            {/* <Route path="/resource/manifest.json" element={<Manifest />} /> */}
            <Route path="/resource/:id" element={<Detail />} />
            <Route path="/resources/:id" exact element={<More />} />
            <Route path="/resources" exact element={<More />} />
            <Route path="/privacy" exact element={<PrivacyPolicyPage />} />
            <Route path="/privacy_shield" exact element={<PrivacyShield />} />
            {/* <Route path="/dashboard" element={<Dashboard /></RequireAuth>} /> */}
            </Routes>
        </AuthProvider>
    
);

export default AppRoute;
