import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ButtonGroup, Image, Menu,
  MenuButton, MenuList, MenuItem, Flex, Box,
  Spacer, Button,  Show, Portal, Hide, List
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FaHamburger } from 'react-icons/fa';
import { useAuth } from '../auth/auth';

const menuItems = [
  {
    id: 1,
    title: 'Home',
    url: '/'
  },
  {
    id: 2,
    title: 'About API',
    url: '/about'
  },
  {
    id: 3,
    title: 'Resources',
    url: '/resource'
  },
  {
    id: 4,
    title: 'Contact',
    url: '/contact'
  }
];

const Header = ({ currentPageUrl }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const auth = useAuth();
  let navigate = useLocation();

  console.log(currentPageUrl);

  return (
    <Box
     px={{ base: 10, sm: 10, md: 10, lg:100, xl: 150, '2xl': 400 }} 
     >
      <Flex minWidth='max-content' alignItems='center' gap='2'>
      <Box p='2'>
        <Image src={'/logo/logo.png'}></Image>
        </Box>
      <Spacer />
      <Hide below='md' p={2}>
      <Box p='2' display={'flex'}>
          {menuItems.map(menuItem => (
            <Box
            mx={5}
            border={menuItem.url === currentPageUrl ? '1px solid #233065' : ''}
            borderRadius="32px"
            padding="8px 16px"
            fontFamily='Lexend'
            fontStyle='normal'
            fontWeight='600'
            fontSize='14px'
            lineHeight='18px'
            key={menuItem.id}
            
            >
              <Link
                to={menuItem.url}
            >
              {menuItem.title}
              </Link>
              </Box>
              //  {menuItem.hasSubMenu && (
              //   <ul className="dropdown-menu">
              //     {menuItem.subMenuItems.map(subMenuItem => (
              //       <li className="dropdown-item" key={subMenuItem.id}>
              //         <a href={subMenuItem.url}>{subMenuItem.title}</a>
              //       </li>
              //     ))}
              //   </ul>
              // )}
          ))}
        </Box>
      </Hide>
        {!auth.user ? <>
          <Link to='/login'>
            <Button borderRadius='8px' outline={'#233065'}
              fontFamily='Lexend'
              fontStyle='normal'
              fontWeight='600'
              fontSize='14px'
              lineHeight='18px'
            >Login</Button>
          </Link>
          <Link to='/register'>
            <Button bg={'#233065'} color={'white'} borderRadius='8px'
              fontFamily='Lexend'
              fontStyle='normal'
              fontWeight='600'
              fontSize='14px'
              lineHeight='18px'
            >Register</Button>
          </Link>
        </> : <Button borderRadius='8px' bg={'#233065'} color={'white'}
              onClick={()=>{ auth.logout()}}
              fontFamily='Lexend'
              fontStyle='normal'
              fontWeight='600'
              fontSize='14px'
              lineHeight='18px'
            >Logout</Button>}
      
      <Show below='md'>
      <Menu>
          <MenuButton>
            
            <FaHamburger></FaHamburger>
        </MenuButton>
        <Portal>
            <MenuList zIndex='99999'>
              {menuItems.map(menuItem => (
              
            <Link
              border={menuItem.url === currentPageUrl ? '1px solid #233065' : ''}
              key={menuItem.id}
              to={menuItem.url}
                >
                <MenuItem>
                    {menuItem.title}
                    </MenuItem>
            </Link>
              //  {menuItem.hasSubMenu && (
              //   <ul className="dropdown-menu">
              //     {menuItem.subMenuItems.map(subMenuItem => (
              //       <li className="dropdown-item" key={subMenuItem.id}>
              //         <a href={subMenuItem.url}>{subMenuItem.title}</a>
              //       </li>
              //     ))}
              //   </ul>
              // )}
          ))}
            {/* <MenuItem>Menu 1</MenuItem>
            <MenuItem>New Window</MenuItem>
            <MenuItem>Open Closed Tab</MenuItem>
            <MenuItem>Open File</MenuItem> */}
          </MenuList>
        </Portal>
      </Menu>
      </Show>
      </Flex>
  </Box>
 
    // <header className="navbar navbar-expand-lg bg-light py-3 px-5">
    //   <div className='container'>
    //   <div className="navbar-brand"><img src='/images/logo.png' alt='site-logo' width={'150px'}/></div>
    //     <nav className='collapse navbar-collapse mr-auto' id="navbarToggleExternalContent" style={{ flexGrow: 0 }}>
 
    //       {auth.user ?          
                 
    //              <div >
    //                      <Menu>
    //                          <MenuButton>
    //                    <Flex>
    //                    <Avatar
    //                        size={'md'}
    //                        src={
    //                          `/images/users/capentry.jpg`
    //                        }
    //                        alt={'Avatar Alt'}
    //                        pos={'relative'}
    //                      />
    //                      <Center>
    //                        <FaChevronDown />
    //                        </Center>
    //                      </Flex>      
    //                  </MenuButton>
    //                 <MenuList>
    //                     <MenuItem><Link to='/dashboard'>Dashboard</Link></MenuItem>
    //                    <MenuItem onClick={() => {
    //               auth.logout(); navigate('/');	}}>Logout</MenuItem>
    //                  </MenuList>
    //                </Menu>
       
    //              </div> 
    //         :
    //       <div className="d-flex">
            
    
    //       </div> 
    //     }

    
    //   </nav>
    //   <button
    //     className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation"
    //     onClick={() => setIsMenuOpen(!isMenuOpen)}
    //   >
    //     <span class="navbar-toggler-icon"></span>
    //     </button>
    //     </div>
    // </header>
  );
};

export default Header;
