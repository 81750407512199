import React, { useState, useEffect } from 'react';
import {
  Box, SimpleGrid, Image, Center,
  Text, Hide, Button,
} from '@chakra-ui/react';
import Header from '../components/headerComponent';
import Footer from '../components/footerComp';
import LoadingIndicator from '../components/loading';
import { Index } from '../services';
import { Link } from 'react-router-dom';
import PolicySlider from '../components/policySlider';
import SEO from '../components/SEO';


const HomePage = () => {
  const [loading, setIsLoaded] = useState(true);
  const [policy, setPolicy] = useState([]);
  useEffect(() => {
    setIsLoaded(true);
    const newData = new Index();
    newData.findFPolicy().then((response) => {
      console.log(response)
      // if (response.statusCode == 200) {
      setPolicy(response);
      setIsLoaded(false);
        
      // }
    })
  }, []);

  return (

    <Box>
      
    {loading ?
         
          <LoadingIndicator />
        
      
        :
        <>
        <SEO
          description={"API helps you understand the impact of policy and legislation on your business while providing you with the tools to influence policy development and adapt to regulatory realities."}
          keywords="Advocacy for Policy and Innovation, policy, legislation, white paper, regulatory, API Intelligence, advocacy, innovation, advocacy for policy and innovation"
          ></SEO>
      <Header currentPageUrl={window.location.pathname}></Header>

      <Center
        position={'relative'}
        w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
        bgRepeat={'no-repeat'}
        backgroundSize={'cover'} height={600}
      >
          <Box
          position={'absolute'}
          zIndex={'888'}
          opacity="0.7"
          height={600}
          backgroundSize={'cover'} 
          w={{ base: '412px', sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
          bgRepeat={'no-repeat'}
         background="linear-gradient(180deg, #233065 0%, #00061C 100%)"></Box>
        <video 
          style={{ height: 600, width: '100%', objectFit: 'cover', objectPosition: 'center' }} autoPlay loop>
          <source src='/videos/home.mp4' type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        <Box px={{ base: 10, md: 150 }} position='absolute'
        zIndex={'9999'}
        >
          <Text color={'white'} w={{ base: "auto", md: "885px" }}
            height={{ base: "auto", md: "113px" }} fontFamily='Manrope' fontStyle='normal' fontWeight='800'
            fontSize={{ base: '30px', md: '40px' }} lineHeight={{ base: '45px', md: '55px' }} textAlign='center' textTransform='capitalize'
            // border="1.09274px solid #1B1C57"
            >
            THE PREMIER PLATFORM FOR AFRICAN <Hide below='md'><br/></Hide> POLICY INTELLIGENCE AND COLLABORATION
          </Text>
          
          <Text textAlign={'center'} color={'white'} mt={50}
            height={{ base: 'auto', md: '84px' }}         
            fontFamily='Lexend' fontStyle='normal' fontWeight='400' fontSize='16px'
            lineHeight='28px'
          >
            API helps you understand the impact of policy and legislation on your business
            <Hide below='md'><br />
            </Hide>
              while providing you with the tools to influence policy development and adapt to <br />regulatory realities.</Text>
          <Center>
          <Link to={'/resource'}>
          <Button bg={'#EDF484'} color={'#233065;'}
              mt={10}
              fontSize={'16px'}
            p={'12px 24px'}
            width={'180px'} height={'54px'}
            textAlign={'center'}l
              alignSelf={'center'}
              borderRadius='12px'
              fontFamily='Manrope'
              fontStyle='normal'
              fontWeight='700'
              
              > VIEW RESOURCES</Button>  
              </Link>
            </Center>
        </Box>
      
      </Center>
      <Box my={{ base: 10, md: 150 }}
        w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
      >
        
        <SimpleGrid px={{ base: 10, sm: 10, md: 50, lg: 150,'2xl': 400, }} columns={{ base: '1', md: '2' }} spacing={{ base: '40px', md: '40px' }}
        w={{ base: 'fit-content', md: '1080px', lg:'fit-content' }}
        >
          <Box>
            <Image width={{ base: 'auto', md: 'auto' }}
              height={{ base: 'auto', md: 'auto' }}
            src={'/images/explain-policy.png'}></Image>
          </Box>
          <Box w={'100%'}>
            <Text
              fontFamily='Manrope'
              fontStyle='normal'
              fontWeight='800'
              fontSize={{ base: '35px', md: '43px' }}
              lineHeight={{ base: '40px', md: '56px' }}
              letterSpacing='-0.03em'
              color='#000000'
              mt={55}
            >Need to understand complex policy information?</Text>
            <Text fontFamily='Manrope' fontStyle='normal' fontWeight='500'
              fontSize='19px' lineHeight='26px'
              letterSpacing='-0.02em'
              color='#64607D'
              mt={10}
            >
              API helps Policy teams to digest and articulate policy information in a way that is relevant and actionable by their key target audiences.</Text>
              <Link to={'/resource'}>
              <Button color={'#fff'} bg={'#233065;'}
              zIndex={9999}
              mt={10}
              fontSize={'12px'}
                p={'12px 24px'}
                width={'180px'} height={'54px'}
                textAlign={'center'}
                  alignSelf={'center'}
                  borderRadius='12px'
            > VIEW RESOURCES</Button>  
            </Link>
          </Box>
        </SimpleGrid>
        <Image src={'/images/maskgroupwhite.png'} position={'absolute'} marginTop={'-390px'} w={{ base: '100px', md: 'fit-content', lg: '1200px', xl: 'auto' }}></Image>
      </Box>
      <Box background={'url(/images/bg-policy.png)'} height={{ base: 'auto', md: '736px' }}
      w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }} bgRepeat={'no-repeat'}
        backgroundSize={'cover'}>
        <Image src='/images/maskgroupblue.png' position={'absolute'} w={{ base: '100px', md: 'fit-content', lg: '1200px', xl: 'auto' }}></Image>
        <Box px={{ base: 10, md: 150, '2xl': 400 }} my={{ base: 10, md: 180}} pb={5}>
          <br />
          <br />
          <Text
          fontFamily="Manrope"
          fontStyle="normal"
          fontWeight="800"
          fontSize={{ base: '35px', md: "40px" }}
          lineHeight={{ base: "48px", md:"66px" }}
          letterSpacing="-0.03em"
          color="#F7FA62"
          textAlign={'center'}
          >
            Our Policy Coverage</Text> 
          <Center>
          <Text
              fontFamily='Manrope'
              fontStyle='normal'
              fontWeight='500'
              fontSize='16px'
              lineHeight={{ base: '24px', md: '29px' }}
              textAlign='center'
              letterSpacing='-0.02em'
              color="white"
              w={{ base: '100%', md: '68%' }}
              mt={5}
          >At Advocacy for Policy and Innovation (API) we provide the latest and most accurate policy information and analysis targeted at technology-based investments, innovations and taxation on the following areas:</Text>
          </Center>
          
          <Center mt={20}> 
            <PolicySlider policy={policy}></PolicySlider>
      </Center>
      </Box>
      </Box>
      <Box px={{ base: 10, md: 150, '2xl': 400 }} my={{ base: 10, md: 180}} w={{ base: 'fit-content', md: '1180px', lg:'1200px', xl:'auto' }}>
        <SimpleGrid columns={{ base: '1', md: '2' }} spacing='40px'>
   
          <Box>
            <Text fontFamily='Manrope' fontStyle='normal' fontWeight='700' fontSize='22px' lineHeight='36px'
              letterSpacing='-0.03em'
              color='#000000'>"Existing policy incentives are underutilized by digital entrepreneurs because of complex and burdensome requirements, confusing application processes, and a lack of transparency."
              </Text>
            <Text fontFamily='Manrope' fontStyle='normal' fontWeight='700' fontSize='18px' lineHeight='26px' letterSpacing='-0.02em'
              color='#000000' mt={10}>
              WORLD BANK GROUP
            </Text>
            <Text fontFamily='Manrope' fontStyle='normal' fontWeight='500' fontSize='16px' lineHeight='26px' letterSpacing='-0.02em'
              color='#64607D'>
              NIGERIA DIGITAL ECONOMY DIAGNOSTIC REPORT
            </Text>
              
          </Box>
          <Box>
            <Image src={'/images/quote.png'} w={{base:'full', '2xl': '70%'}}></Image>
          </Box>
          <Hide below='md'>
            <Image src='/images/right.png' position={'absolute'} right={0} marginTop={'-300px'}></Image>
          </Hide>
        </SimpleGrid>
      </Box>
      <Footer></Footer>
      </>}
    </Box>
    
    
  );
};

export default HomePage;
