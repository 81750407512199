import logo from './logo.svg';
import './App.css';
import AppRoute from '../src/route/index'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


function App() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
          console.log('Service worker registered: ', registration);
        })
        .catch(error => {
          console.log('Service worker registration failed: ', error);
        });
    });
  }
  
  return (
    <AppRoute></AppRoute>
  );
}

export default App;
