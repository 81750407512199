import React from "react";
import { Spinner, Text } from "@chakra-ui/react";

const LoadingIndicator = () => (
  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
    <Spinner size="xl" />
    <Text ml={3}>Loading...</Text>
  </div>
);

export default LoadingIndicator;
