import React, { useState, useEffect } from 'react';
import {
    Box, SimpleGrid, Image, Center,
    Text, Hide, Button, Flex, CardBody,
    Card,
    CardHeader,
    UnorderedList,
    ListItem
} from '@chakra-ui/react';
import Header from '../components/headerComponent';
import Footer from '../components/footerComp';
import LoadingIndicator from '../components/loading';
import { Index } from '../services';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';
const Resources = () => {
  const [loading, setIsLoaded] = useState(true);
  const [resources, setResources] = useState({});
  const [whitepaper, setWP] = useState([]);
  const [technology, setTP] = useState([]);
  const newData = new Index();
  useEffect(() => {
    setIsLoaded(true);
    newData.findWP().then((response) => {
      console.log(response)
      // if (response.statusCode == 200) {
      setWP(response);
      setIsLoaded(false);
        
      // }
    })
  }, []);
  useEffect(() => {
    setIsLoaded(true);
    newData.findTP().then((response) => {
      console.log(response)
      // if (response.statusCode == 200) {
      setTP(response);
      setIsLoaded(false);
        
      // }
    })
  }, []);
  useEffect(() => {
    setIsLoaded(true);
    newData.findSlider().then((response) => {
      console.log(response)
      // if (response.statusCode == 200) {
      setResources(response);
      setIsLoaded(false);
        
      // }
    })
  }, []);


  return (

    <Box>
      
    {loading ?
         
          <LoadingIndicator />
        :
        <>
        <SEO
         title="Resources"
          description={` At Advocacy for Policy and Innovation (API) we provide up-to-date and accurate policy information and analysis targeted at technology-based investments, innovation and taxation.
          `}
      keywords="API Resources, policy, legislation, white paper, regulatory, API Intelligence, advocacy, innovation, advocacy for policy and innovation"
></SEO>
        <Header currentPageUrl={window.location.pathname}></Header>
      <Center position={'relative'}
        w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
        bgRepeat={'no-repeat'}
        backgroundSize={'cover'} height={600}>
           <Box
          position={'absolute'}
          zIndex={'888'}
          opacity="0.7"
          height={600}
          backgroundSize={'cover'} 
          w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
          bgRepeat={'no-repeat'}
         background="linear-gradient(180deg, #233065 0%, #00061C 100%)"></Box>
        <video 
          style={{ height: 600, width: '100%', objectFit: 'cover', objectPosition: 'center' }} autoPlay loop>
          <source src='/videos/resources.mp4' type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        <Box position={'absolute'}
        zIndex={'9999'}
          px={{ base: 10, md: 150, '2xl': 400 }}>
          <Text color={'white'}
            justifyContent={'center'}
            fontFamily='Manrope' fontStyle='normal' fontWeight='800'
            lineHeight={{ base: '40px', md: '49px' }}
            
            fontSize={{ base: '30px', md: '36px' }}  textAlign='center' 
            mt={10}          
            // border="1.09274px solid #1B1C57"
                  >
            {resources ? <Text 
            height={{ base: "auto", md: "100px" }} > {resources.title}</Text> : "Useful Resources for Policy Teams"}
          </Text>
          
          <Text textAlign={'center'} color={'white'} 
            height={{ base: 'auto', md: '84px' }}         
            fontFamily='Lexend' fontStyle='normal' fontWeight='400' fontSize='16px'
            lineHeight='28px'
            justifyContent={'center'}
            display="contents"
          >
            {resources ? <Center display="contents" dangerouslySetInnerHTML={{ __html: resources.description }}
             justifyContent={'center'} textAlign={'center'} 
            ></Center> : <Text>At Advocacy for Policy and Innovation (API) we provide up-to-date and,<Hide below='md'><br /></Hide>
              accurate policy information and analysis targeted at technology-based <Hide below='md'><br /></Hide>
              investments, innovation and taxation.</Text>}

                      
            
         </Text>
        
          <Center>
            <Link to={"/resource/"+resources._id}>
          <Button bg={'#EDF484'} color={'#233065;'}
              mt={10}
              fontSize={'16px'}
            p={'12px 24px'}
            width={'180px'} height={'54px'}
            textAlign={'center'}l
              alignSelf={'center'}
              borderRadius='12px'
              fontFamily='Manrope'
              fontStyle='normal'
              fontWeight='700'
              
            > VIEW DETAIL</Button>  
            </Link>
            </Center>
        </Box>
          </Center>
          <Box px={{ base: 10, md: 10, lg: 150, '2xl': 400 }} my={{ base: 10, md: 150 }}>    
            <Flex justifyContent={'center'}>
              <Text
                fontFamily="Manrope"
                fontStyle="normal"
                fontWeight="800"
                fontSize={{ base: '20px', md: "24px" }}
                lineHeight={{ base: "25px", md:"30px" }}
                letterSpacing="-0.03em"
                color='#233065'
                width='489px'
                textAlign={'center'}>
                      Gain insight into policies and legislative bills; have a say in their formulation process
            </Text> 
            </Flex>
        </Box>
          <Box
          
              my={{ base: 10, md: 150 }}
              w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
      >
        
        <SimpleGrid columns={{ base: '1', md: '3' }} spacing={{ base: '40px', md: '40px' }}
          px={{ base: 10, md: 10, lg: 150, '2xl': 400 }}
                >
                  <Box>
                      <Image src='/images/one.png' position={'absolute'} zIndex={'99'}></Image>
                    <Card
                        background='#FFFFFF'
                        boxShadow='0px 95.365px 76.292px rgba(0, 0, 0, 0.03), 0px 61.8106px 44.6803px rgba(0, 0, 0, 0.0227778), 0px 36.7332px 24.3004px rgba(0, 0, 0, 0.0182222), 0px 19.073px 12.3975px rgba(0, 0, 0, 0.015), 0px 7.77048px 6.21638px rgba(0, 0, 0, 0.0117778), 0px 1.76602px 3.00223px rgba(0, 0, 0, 0.00722222)'
                        borderRadius='13.4483px'
                        px={'10px'}  
                        height={'250px'} 
                      >
                        <CardHeader 
                        mt={10}      
                        fontFamily='Manrope'
                        fontStyle='normal'
                        fontWeight='800'
                        fontSize='20px'
                        lineHeight='34px'
                        textAlign={'center'}      
                              
                    >Policy Intelligence Solutions</CardHeader>
                    <CardBody
                            fontFamily='Manrope'
                            fontStyle='normal'
                            fontWeight='500'
                            fontSize='14px'
                            lineHeight='20px'
                            textAlign='center'
                            letterSpacing='-0.02em'
                            color='#64607D'
                            marginTop={'-40px'}  
                          >
                        We provide information, analysis and intelligence on Policy through our White Papers, Intelligence Briefings and Policy Situation Room Events
                    </CardBody>          
                </Card>
            </Box>
                  <Box>
                  <Image src='/images/two.png' position={'absolute'} zIndex={'99'}></Image>
                    <Card
                        background='#FFFFFF'
                        boxShadow='0px 95.365px 76.292px rgba(0, 0, 0, 0.03), 0px 61.8106px 44.6803px rgba(0, 0, 0, 0.0227778), 0px 36.7332px 24.3004px rgba(0, 0, 0, 0.0182222), 0px 19.073px 12.3975px rgba(0, 0, 0, 0.015), 0px 7.77048px 6.21638px rgba(0, 0, 0, 0.0117778), 0px 1.76602px 3.00223px rgba(0, 0, 0, 0.00722222)'
                        borderRadius='13.4483px'
                        px={'5px'}  
                        height={'250px'} 
                      >
                    <CardHeader 
                        fontFamily='Manrope'
                        fontStyle='normal'
                        fontWeight='800'
                        fontSize='20px'
                        lineHeight='34px'
                        textAlign={'center'}
                        mt={10}      
                              
                              
                    >Policy Tracking Solutions</CardHeader>
                    <CardBody
                            fontFamily='Manrope'
                            fontStyle='normal'
                            fontWeight='500'
                            fontSize='14px'
                            lineHeight='20px'
                            textAlign='center'
                            letterSpacing='-0.02em'
                            color='#64607D'
                            marginTop={'-40px'}  
                          >
                       We track bills and other regulatory instruments from conception to delivery and fill you in on what to anticipate in regulation.

                    </CardBody>          
                </Card>
            </Box>
            <Box>
                  <Image src='/images/three.png' position={'absolute'} zIndex={'99'}></Image>
                    <Card
                        background='#FFFFFF'
                        boxShadow='0px 95.365px 76.292px rgba(0, 0, 0, 0.03), 0px 61.8106px 44.6803px rgba(0, 0, 0, 0.0227778), 0px 36.7332px 24.3004px rgba(0, 0, 0, 0.0182222), 0px 19.073px 12.3975px rgba(0, 0, 0, 0.015), 0px 7.77048px 6.21638px rgba(0, 0, 0, 0.0117778), 0px 1.76602px 3.00223px rgba(0, 0, 0, 0.00722222)'
                        borderRadius='13.4483px'
                        px={'15px'}  
                        height={'250px'} 
                      >
                    <CardHeader 
                        mt={5}
                        fontFamily='Manrope'
                        fontStyle='normal'
                        fontWeight='800'
                        fontSize='20px'
                        lineHeight='24px'
                        textAlign={'center'}   
                           
                    >Policy Stakeholder Management Solutions</CardHeader>
                    <CardBody
                            fontFamily='Manrope'
                            fontStyle='normal'
                            fontWeight='500'
                            fontSize='14px'
                            lineHeight='20px'
                            textAlign='center'
                            letterSpacing='-0.02em'
                            color='#64607D'
                            marginTop={'-40px'}  
                          >
                      With our policy directories, policy dashboard and advocacy interventions, you can gain more control over stakeholder management and policy influence

                    </CardBody>          
                </Card>
            </Box>
        </SimpleGrid>
              <Image
                  src={'/images/maskgroupwhite.png'} position={'absolute'}
          marginTop={'-390px'}
          w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
                  zIndex='-9'
              ></Image>
              <Flex justifyContent={'center'}>
              <Center>
          {/* <Button bg={'#233065'} color={'#FFF'}
              mt={10}
              fontSize={'14px'}
            
            width={'180px'} height={'54px'}
            textAlign={'center'}l
              alignSelf={'center'}
              borderRadius='12px'
              fontFamily='Manrope'
              fontStyle='normal'
              fontWeight='700'
              
            > REQUEST DEMO</Button>   */}
            </Center>  
            </Flex>
          </Box>
    <Box px={{ base: 10, md: 150, '2xl': 400}} my={{ base: 1, md: 180 }}
              w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
              height={'full'} justifyContent={'center'}>
       
              <Center  py={5}
              width='auto'
              height='312px'
              left='0px'
              top='0px'
              mt={100}
              background='radial-gradient(50% 50% at 50% 50%, #233065 0%, #000000 100%)'
              borderRadius='32px'
              >
          
        <Box p={'10'}>
            <Text color={'#FFF'} lineHeight={'48px'}
              fontSize={'32px'} fontWeight={'600'}
              fontFamily={'Lexend'} textAlign={'center'}>
            Our Team
            </Text>
            <Center>
            <Text
            fontFamily='Public Sans'
            fontStyle='normal'
            fontWeight='400'
            fontSize='16px'
            lineHeight='24px'
            textAlign='center'
            letterSpacing='-0.02em'
            color="#FFF"
            w='58%'
              >API Founders have firsthand experience and insights having worked on different sides of the policy frontline.</Text>
              </Center>
            </Box>
            <Hide below='md'>
          <Image src='/images/right.png' position={'absolute'} right={0} marginTop={'-290px'}></Image>
          </Hide>
      </Center>
      </Box>
      <Box my={{ base: 10, md: 150 }}
      w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
      >
        
        <SimpleGrid columns={{ base: '1', md: '2' }} spacing={{ base: '40px', md: '40px' }}
        px={{ base: 10, md: 10, lg: 150, '2xl': 400 }} 
        // w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
        >
          <Box>
            <Image width={{ base: '280px', md: 'auto' }}
              height={{ base: 'auto', md: 'auto' }}
            src={'/images/whitepaper.png'} zIndex={'8888'}></Image>
          </Box>
          <Box w={'100%'} zIndex={'99999'}>
            <Text
              fontFamily='Manrope'
              fontStyle='normal'
              fontWeight='800'
              fontSize={{ base: '30px', md: '35px' }}
              lineHeight={{ base: '40px', md: '56px' }}
              letterSpacing='-0.03em'
              color='#000000'
              mt={15}
            >White Paper</Text>
            <UnorderedList>
              {whitepaper && whitepaper.map((pol) => (
                <Link to={`/resource/${pol._id}`}>
                <ListItem fontFamily='Manrope' fontStyle='normal' fontWeight='500'
                  fontSize='16px' lineHeight='26px'
                  letterSpacing='-0.02em'
                  color='#64607D'>
                  {pol.title}
                </ListItem>
                </Link>
              ))}
            </UnorderedList>
            <Link to={`/resources`}>
              <Button color={'#fff'} bg={'#233065;'}
                mt={10}
                w={150}
              fontSize={'12px'}
                p={'12px 24px'}
                height={'54px'}
                textAlign={'center'}
                  alignSelf={'center'}
                  borderRadius='12px'
            > SEE MORE </Button>  </Link>
          </Box>
        </SimpleGrid>
        <Image src={'/images/maskgroupwhite.png'} position={'absolute'} marginTop={'-390px'}
          w={{ base: '100px', md: 'max-content', lg: '1200px', xl: '1200px' }} zIndex={'888'}></Image>
          </Box>
      <Box px={{ base: 10, md: 10, lg: 250, '2xl': 400 }} mt={{ base: 10, md: 150 }}
        w={{ base: '412px', sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
        
      >
        
        <SimpleGrid columns={{ base: '1', md: '2' }} spacing={{ base: '40px', md: '80px' }}
        // w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
          mt={20}
        >
          <Box w={'100%'} zIndex={'99999'}>
            <Text
              fontFamily='Manrope'
              fontStyle='normal'
              fontWeight='800'
              fontSize={{ base: '30px', md: '35px' }}
              lineHeight={{ base: '40px', md: '56px' }}
              letterSpacing='-0.03em'
              color='#000000'
              mt={15}
                      >Policies and Regulations</Text>
                      
            <UnorderedList>
              {technology && technology.map((pol) => (
                <Link to={`/resource/${pol._id}`}>
                <ListItem fontFamily='Manrope' fontStyle='normal' fontWeight='500'
                fontSize='16px' lineHeight='26px'
                letterSpacing='-0.02em'
                color='#64607D'>
                  {pol.title}
                  </ListItem>
                  </Link>
              ))}
               
            </UnorderedList>
            <Link to={`/resources`}>
              <Button color={'#fff'} bg={'#233065;'}
                mt={10}
                w={150}
              fontSize={'12px'}
                p={'12px 24px'}
                height={'54px'}
                textAlign={'center'}
                  alignSelf={'center'}
                  borderRadius='12px'
            > SEE MORE </Button>  </Link>
                  </Box>
                  <Box>
            <Image width={{ base: '280px', md: 'auto' }}
              height={{ base: 'auto', md: 'auto' }}
            src={'/images/policies.png'}></Image>
          </Box>
        </SimpleGrid>
          
      </Box>
          <Box background='#F4F5F7' px={{ base: 10, md: 10, lg: 10 }} my={{ base: 10, md: 10 }}
              w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
              py={50}
          >
              <Center
                mt={5}
                fontFamily='Manrope'
                fontStyle='normal'
                fontSize='20px'
                lineHeight='38px'
                letterSpacing='-0.02em'
                fontWeight={'500'}
                textAlign={'center'}
                  color='#64607D'
                >               
                      Get access to all you need, from regulation tracking to policy <Hide below='md'><br /></Hide> analysis and impact research, with an API Subscription.
              </Center>
              <Center>
                  <Button color={'#fff'} bg={'#233065;'}
                    my={10}
              fontSize={'12px'}
                p={'12px 24px'}
                height={'54px'}
                textAlign={'center'}
                  alignSelf={'center'}
                  borderRadius='12px'
            > GET ACCESS </Button>  
                    </Center>
               
        </Box>
      
      <Footer></Footer>
      </>}
    </Box>
    
    
  );
};

export default Resources;
