import React, { useState, useEffect } from 'react';
import {
  Box, SimpleGrid, Image, Center,
  Text, Hide, Button
} from '@chakra-ui/react';
import Header from '../components/headerComponent';
import Footer from '../components/footerComp';
import LoadingIndicator from '../components/loading';
import { Link } from 'react-router-dom';
import { Index } from '../services';
import PageNavigation from '../components/Pagination';
import SEO from '../components/SEO';

const More = () => {
  
  const [loading, setIsLoaded] = useState(true);
  const [policy, setPolicy] = useState([]);
  const [totalPages, setTotalPages] = useState();

  useEffect(() => {
    
    const newData = new Index();
    let mediaId = Number(`${window.location.pathname.split('/').pop()}`);
    let count;
    typeof (mediaId) == "number" ? count = mediaId : count = 1
    newData.findMore(count).then((response) => {
        setPolicy(response.resource);
        setTotalPages(response.totalPages);
        setIsLoaded(false);
        
    })
  }, [policy]);

  return (

    <Box>
      

    {loading ?
         
         <LoadingIndicator />
       
     :
   <>  
             <SEO
         title="More Resources"
          description={`List of all resources`}
        keywords="Advocacy for Policy and Innovation, Contact API, policy, legislation, white paper, regulatory, API Intelligence, advocacy, innovation, advocacy for policy and innovation"
        ></SEO>
    <Header currentPageUrl={window.location.pathname}></Header>
      <Box px={{ base: 10, md: 150, '2xl': 400  }} mt={{ base: 20, md: 20}} w={{ base: 'fit-content', md: '1180px', lg:'1200px', xl:'auto' }}>
        { policy && policy.map((ele)=>{
        return <SimpleGrid columns={{ base: '1', md: '2' }} spacing='40px' my='20'>
          <Box border={'1px solid grey'} borderRadius={'20px'} h={"350px"}>
          
            <Image src={`${process.env.REACT_APP_API_URL}/upload/${ele.picture || '1deacd835812ab7391247a14d0caab96.png'}`} w={'100%'} h={'100%'} borderRadius={'20px'}></Image>
          </Box>
          <Box>
                <Text fontFamily='Manrope' fontStyle='normal' fontWeight="800"
                      fontSize="28px" lineHeight="36px"  letterSpacing='-0.03em'
              color='#000000'>{ele.title}
              </Text>
              <Text fontFamily='Manrope' fontStyle='normal' fontWeight="600"
                      fontSize="14px" lineHeight="36px"  letterSpacing='-0.03em'
              color='#000000'>Category: {ele.category}
              </Text>
            <Text fontFamily='Manrope' fontStyle='normal' fontWeight='500' fontSize='16px' lineHeight='20px' letterSpacing='-0.02em'
                          color='#000000' mt={5} dangerouslySetInnerHTML={{ __html: ele.description }}>
             
            </Text>
            <Link to={`/resource/${ele._id}`}>
            <Button color={'#fff'} bg={'#233065;'}
                mt={10}
                fontSize={'12px'}
                p={'12px 24px'}
              height={'54px'}
              w={100}
                textAlign={'center'}
                alignSelf={'center'}
                borderRadius='12px'
            > VIEW </Button> 
              </Link>
          </Box>
        
          <Hide below='md'>
            <Image src='/images/right.png' position={'absolute'} right={0} marginTop={'-100px'}></Image>
          </Hide>
        </SimpleGrid>
        })} 
        <Text mt={5}>{ isNaN( Number(`${window.location.pathname.split('/').pop()}`)) ? "Page 1" : `Page ${window.location.pathname.split('/').pop()}`}</Text>
        <PageNavigation totalPages={totalPages} currentPage={1}></PageNavigation>
      </Box>
      
      <Footer></Footer>
      </>}
    </Box>
    
    
  );
};

export default More;
