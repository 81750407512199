import React, { useState, useEffect } from 'react';
import { Box, Heading, Text } from "@chakra-ui/react";
import Header from '../components/headerComponent';
import Footer from '../components/footerComp';
import LoadingIndicator from '../components/loading';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';

const PrivacyShield = () => {
  const [loading, setIsLoaded] = useState(true);

  useEffect(() => {
    setIsLoaded(false);
  }, []);
  
  return (
    <Box>
        {loading ?
         <LoadingIndicator />     
        :
        <>
         <SEO
         title="Privacy Shield"
          description={`Advocacy for Policy and Innovation (API) complies with the Nigerian Data Protection Privacy (“Privacy Shield Frameworks“) as set forth by the Nigerian Data Protection Bureau regarding the collection, use and retention of Personal Data (as defined below) transferred from Nigeria to Foreign countries.`}
          keywords="privacy, About API, policy, legislation, white paper, regulatory, API Intelligence, advocacy, innovation, advocacy for policy and innovation"></SEO>
     
      <Header currentPageUrl={window.location.pathname}></Header>
      <Box 
      my={{ base: 10, md: 150 }}
      px={{ base: 10, md: 10, lg: 150, '2xl': 400 }}
      w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}
      >
      <Heading as="h2" fontSize="xl" mb={4}>
        PRIVACY SHIELD
      </Heading>
      <Text>
        Advocacy for Policy and Innovation (API) complies with the Nigerian Data Protection Privacy (“Privacy Shield Frameworks“) as set forth by the Nigerian Data Protection Bureau regarding the collection, use and retention of Personal Data (as defined below) transferred from Nigeria to Foreign countries. API has certified that it adheres to the Privacy Shield Principles with respect to such Personal Data. If the policies in this Privacy Shield Policy and the data subject rights under the Privacy Shield Principles conflict, the Privacy Shield Principles shall govern.
      </Text>
      <Heading as="h3" fontSize="lg" mt={6} mb={2}>
        Definitions
      </Heading>
      <Text>
        In this PRIVACY SHIELD section:
      </Text>
      <Text>
        • "Database" means a collection of data organized in a manner that allows access, retrieval, deletion and procession of that data; it includes but not limited to structured, unstructured, cached and file system type databases;
      </Text>
      <Text>
        • "Foreign Country" means other sovereign states, autonomous or semi autonomous territories within the international community;
      </Text>
      <Text>
        • "Personal Data" means any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person; It can be anything from a name, address, a photo, an email address, bank details, posts on social networking websites, medical information, and other unique identifier such as but not limited to MAC address, IP address, IMEI number, IMSI number, SIM and others;
      </Text>
      <Text>
        • "Sensitive Personal Data" means Data relating to religious or other beliefs, sexual tendencies, health, race, ethnicity, political views, trades union membership, criminal records or any other sensitive personal information; 
      </Text>
      <Text>
        • "Third Party" means any natural or legal person, public authority, establishment or any other body other than the Data Subject, the Data Controller, the Data Administrator and the persons who are engaged by the Data Controller or the Data Administrator to process personal data.
      </Text>
      <Heading as="h3" fontSize="lg" mt={6} mb={2}>
        Privacy Shield Principles
      </Heading>
      <Text>
        API commits to processing Personal Data in accordance with the Privacy Shield Principles as follows:
      </Text>
      <Text fontWeight={'400'}>
        1. Notice
      </Text>
      <Text>
        Prior to collecting Personal Data, API notifies Foreign Users about the categories of Personal Data that API collects and the purposes for collection and use of their Personal Data. API will only process Personal Data in ways that are compatible with the purpose for which API collected it or for purposes later authorized.
        We use the Personal Data that we collect from Foreign Users of the Services as described in this Privacy Policy. Before API uses Personal Data for a purpose that is materially different from the purpose for which Company collected it or that was later authorized, API will provide Foreign Users with the opportunity to opt out.
      </Text>
      <Text fontWeight={'400'}>
         2. Choice
      </Text>
      <Text>
        If the Company collects Sensitive Personal Data, we will obtain explicit opt-in consent whenever Privacy Shield requires.API will obtain opt-in consent before Personal Data is disclosed to third parties other than those described in this Privacy Policy, before Personal Data is used for a different purpose than that purpose for which it was collected or later authorized, and whenever Privacy Shield requires.
        Please see the YOUR CHOICES ABOUT YOUR INFORMATION section above for more information about how to exercise your choices.
      </Text>
      <Text fontWeight={'400'}>
        3. Accountability for Onward Transfer
      </Text>
      <Text>
        API shares Personal Data collected through the Services as described above.
        If API transfers Personal Data to a third-party, API takes reasonable and appropriate steps to ensure that each third-party transferee processes Personal Data transferred in a manner consistent with API’s obligations under the Privacy Shield Principles. API will ensure that each transfer is consistent with any notice provided to Foreign Users and any consent they have given. API requires a written contract with any third party receiving Personal Data that ensures that the third party 
        (i) processes the Personal Data for limited and specified purposes consistent with any consent provided by Foreign Users, 
        (ii) provides at least the same level of protection as is required by the Privacy Shield Principles, 
        (iii) notifies API if it cannot comply with Privacy Shield; and 
        (iv) ceases processing Personal Data or takes other reasonable and appropriate steps to remediate.
        As noted above, under certain circumstances, API may be required to disclose Personal Data in response to valid requests by public authorities, including for national security or law enforcement requirements.
        API remains liable under the Privacy Shield Principles if an agent processes Personal Data in a manner inconsistent with the Principles unless the Company is not responsible for the event giving rise to the damage.
      </Text>
      <Text fontWeight={'400'}>
        4. Security
      </Text>
      <Text>
        API takes appropriate measures to protect Personal Data from loss, misuse and unauthorized access, disclosure, alteration, unavailability and destruction. In determining these measures, the Company considers the risks involved in the processing and the nature of the Personal Data.
      </Text>
      <Text fontWeight={'400'}>
        5. Data Integrity and Purpose Limitation
      </Text>
      <Text>
        API takes reasonable steps to ensure that such Personal Data is reliable for its intended use, accurate, complete and current. API adheres to the Privacy Shield Principles for as long as it retains Personal Data in identifiable form. API takes reasonable and appropriate measures to comply with the requirement under the Privacy Shield to retain Personal Data in identifiable form only for as long as it serves a purpose of processing.
        API limits the collection of Personal Data to information that is relevant for processing. API does not process Personal Data in a way that is incompatible with the purpose for which it was collected or subsequently authorized by a Foreign User.
      </Text>
      <Text fontWeight={'400'}>
        6. Access
      </Text>
      <Text>
        A Foreign User has the right to access his or her Personal Data and to correct, amend, limit use of or delete the Personal Data if the Personal Data is inaccurate or processed in violation of the Privacy Shield Principles. API is not required to grant the rights to access, correct, amend and delete Personal Data if the burden or expense of providing access, correction, amendment or deletion is disproportionate to the risks to the Foreign User’s privacy or if the rights of persons other than the Foreign User are or could be violated.
        Please see the YOUR CHOICES ABOUT YOUR INFORMATION section above for more information about how to exercise your choices.
      </Text>
      <Text fontWeight={'400'}>
        7. Recourse, Enforcement, and Liability
      </Text>
      <Text>
        In compliance with the Privacy Shield Principles, API commits to resolve complaints about your privacy and our collection or use of your Personal Data transferred to Nigeria pursuant to the Data Protection Regulations. Foreign individuals with Privacy Shield inquiries or complaints should first contact API at info@api.org
        If your Privacy Shield complaint cannot be resolved by other redress mechanisms API has further committed to refer such unresolved complaints under the Privacy Shield Principles to Alternative Dispute Resolution mechanism, Chartered Institutes of Arbitrators Nigeria and its proceedings will be governed by the Nigerian Arbitration Laws. 
        API commits to periodically review and verify its compliance with the Privacy Shield Principles and to remedy any issues arising out of failure to comply with the Privacy Shield Principles.
      </Text>
      
    </Box>
    <Footer></Footer>
    </>
    }
    </Box>
  );
};

export default PrivacyShield;
