import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.initialize("G-WLXR5YYGMH");
root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
    <ChakraProvider>
    <App />
      </ChakraProvider>
      </BrowserRouter>
  </React.StrictMode>
);
const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
