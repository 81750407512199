import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, keywords}) => {
  return (
    <>
      <Helmet>
        {title ? 
        <title>{title} - API Intelligence</title> : <title> Advocacy for Policy and Innovation</title>}
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
    </>
  );
};

export default SEO;
