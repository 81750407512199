import {
    Box,
    Container,
    SimpleGrid,
    Stack,
    Text,
    Hide,
    Center,
    InputGroup,
    Input,
    Image,
    useColorModeValue,
    HStack,
  } from '@chakra-ui/react';
import { FiFacebook, FiInstagram, FiTwitter, FiLinkedin } from 'react-icons/fi';
 import { Link } from 'react-router-dom'; 

  const ListHeader = ({ children }) => {
    return (
      <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
        {children}
      </Text>
    );
  };
  
  const Footer = () => {
    return (

      
      <Box w={{ base: '412px',  sm: '569px', md: '1177px', lg: '1200px', xl: '100%' }}>
            <Box px={{ base: 5, md: 150 }} my={{ base: 1, md: 40 }}
        w={{ base: 'fit-content', md: '1180px', lg: '1180px', xl: 'full', '2xl':'full' }} 
        justifyContent={'center'}>
        <Hide below='md'>
          <Image src='/images/left.png' position={'absolute'} left={0} marginTop={'-390px'}></Image>
          </Hide>
 
  <Box
    zIndex={100}
    borderRadius="40px"
    p={10}
    background="linear-gradient(90deg,  rgba(180,202,246,1) 3%, rgba(207,215,247,1) 100%, rgba(0,0,0,1) 100%)"
    w="100%"
    maxW="900px"
    mx="auto"
    justifyContent={'center'}
    justifyItems={'center'}
    display={'flex'}
  >
    {/* #ADC6F6,#F1FBF8,#E8EAF7 */}
    <Box  w="100%"
    maxW="600px" >
    <Text
      color="rgb(27, 28, 87)"
      fontSize="32px"
      fontWeight="600"
      fontFamily="Lexend"
      textAlign="center"
    >
      Get the latest from API
    </Text>
    <Center>
      <Text
        fontFamily="Manrope"
        fontStyle="normal"
        fontWeight="500"
        fontSize="16px"
        lineHeight="29px"
        textAlign="center"
        letterSpacing="-0.02em"
        color="rgba(0, 0, 0, 0.54)"
        w="58%"
      >
        Don't miss out on the latest API news, policy update and white papers
      </Text>
    </Center>
    <form
      action="https://apiintelligence.us1.list-manage.com/subscribe/post?u=468a1952f5d1216c1fae65fa0&amp;id=7723bce59a&amp;f_id=00cac7e5f0"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className="validate"
      target="_blank"
      noValidate
      display="flex"
    >
      {/* ... */}

      <InputGroup bg="white" borderRadius={60} py="3" mt={6}>
        <Image
          src="/images/envelope.png"
          mx={4}
          width="50px"
          height="20px"
          mt={3}
        ></Image>
        <Input type="email" name="EMAIL" placeholder="Your email here" p="5" />
        <Input
          background="#233065"
          borderRadius="32px"
          type="submit"
          name="subscribe"
          className="button"
          value="Subscribe"
          cursor="pointer"
          display="inline-block"
          flexDirection="row"
          alignItems="center"
          w="50%"
          color="white"
          gap="4px"
        />
      </InputGroup>
    </form>
  </Box>
  </Box>


      </Box>
     <Box
        mt={5}
        bg={useColorModeValue('white', 'white')}
        px={ {base:10, md: 150}}
        w={{ base: 'fit-content', md: '1180px', lg: '100%', '2xl': '100%' }}
        fontFamily='Lexend'
        fontStyle='normal'
        lineHeight='24px'
      >
        <Container as={Stack} maxW={'7xl'} py={10} mt={5}>
          <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={10}>
            <Stack align={'flex-start'}>
            <Image src={'/logo/logo.png'}></Image>
            <Text fontWeight='400'
        fontSize='14px'
        color='#626687'>We are revered for providing real-time, verifiable and valuable policy intelligence to both existing and potential investors.</Text>
              <HStack>
                <a href='https://facebook.com/profile.php?id=100071229022347'
                target="_blank" rel="noreferrer">
                <FiFacebook></FiFacebook>
                </a>
                <a href='https://twitter.com/apiintelligence' rel="noreferrer" target="_blank">
                <FiTwitter></FiTwitter>
                </a>
                <a href='https://instagram.com/advocacyforpolicyandinnovation?igshid=YmMyMTA2M2Y='
                 target="_blank" rel="noreferrer">  
                 <FiInstagram></FiInstagram></a>

                <a href='https://linkedin.com/company/advocacy-for-policy-and-innovation-api'
                target="_blank" rel="noreferrer">
                <FiLinkedin></FiLinkedin>
                </a>
            </HStack>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader fontWeight='600' fontSize='18px' color='#0E1735'
            >Quick Links</ListHeader>
              <Link fontWeight='400'
        fontSize='11px'
        color='#626687' to={'/about'}>About Us</Link>
              <Link fontWeight='400'
        fontSize='11px'
        color='#626687' to={'/resource'}>Resources</Link>
          <Link fontWeight='400'
        fontSize='11px'
        color='#626687' to={'/privacy'}>Privacy</Link>
          <Link fontWeight='400'
        fontSize='11px'
        color='#626687' to={'/privacy_shield'}>Privacy Shield</Link>
              <Link fontWeight='400'
        fontSize='11px'
        color='#626687'to={'/contact'}>Contact</Link>
              <Link fontWeight='400'
        fontSize='11px'
        color='#626687' to={'/condition'}>API Condition of Service</Link>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader fontWeight='600' fontSize='18px' color='#0E1735'>Policy Coverage</ListHeader>
              <Text fontWeight='400'
        fontSize='14px'
        color='#626687' href={'#'}>Digital Economy</Text>
              <Text fontWeight='400'
        fontSize='14px'
        color='#626687' href={'#'}>Immigration</Text>
              <Text fontWeight='400'
        fontSize='14px'
        color='#626687' href={'#'}>Business Requirement and Regulation</Text>
              <Text fontWeight='400'
        fontSize='14px'
        color='#626687' href={'#'}>Incentives and Levies</Text>
              <Text fontWeight='400'
        fontSize='14px'
        color='#626687' href={'#'}>Trade Policy and Facilitation</Text>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader fontWeight='600' fontSize='18px' color='#0E1735'>Contact</ListHeader>
              <Text fontWeight='400'
        fontSize='14px'
        color='#626687'>
                Ventures Platform Gallery,
                No 29 Mambilla Street,
                Maitama,Abuja FCT.
              </Text>
              <Text fontWeight='400'
        fontSize='14px'
        color='#626687'>+234 907 545 0934</Text>
              <Text fontWeight='400'
        fontSize='14px'
        color='#626687'>hello@apiintelligence.org</Text>
            </Stack>
          </SimpleGrid>
        </Container>
        <Box py={10}>
          <Text pt={6} fontSize={'sm'} textAlign={'center'} fontWeight='400'
        color='#626687'>
            © 2023 API Intelligence. All rights reserved 
                </Text>
            {/* <Text fontSize={'sm'} textAlign={'center'} fontWeight='400'
        color='#626687'><Link href='https://ebenishola.com'>By Ebenezer Ishola</Link></Text> */}
        </Box>
        </Box>
      </Box>
    );
}
export default Footer;