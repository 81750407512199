import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import {
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Text,
  InputGroup,
  InputLeftElement,
  Center,
  Input,
  Button,
  Select,
  Image,
  CircularProgress
} from '@chakra-ui/react';
import { Country } from 'country-state-city';
import { Index } from '../services/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEnvelope } from 'react-icons/fa';
import GoBackButton from '../components/backIcon';

const Register = () => {
  let navigate = useNavigate();
  const countries = Country.getAllCountries();  
    const [user, setUser] = useState({
        email: '', password: '',
        firstName: '', lastName: '', type: 'user',
        country: '', state: '', title: ''
      });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevState => ({
          ...prevState,
          [name]: value,
        }));
    };
    
  const handleSubmit = (event) => {
    event.preventDefault();
    document.querySelector("#loader").style = "display:none";

    console.log(event)
    const index = new Index();
    index.createUser(user).then(
      (res) => {
        console.log(res)
        if (res.statusCode === 200) { 
          document.querySelector("#loader").style="display:none";
            toast.success(res.message)
            setTimeout(() => navigate("/login"), 1000)
        } else {
          document.querySelector("#loader").style="display:none";
          toast.error(res.message)
        }
        }
        );
      };
  return (
    <Flex minH={'100vh'}>
    <Flex minH={'100vh'} w="100%" direction={"column"}>
      <Flex h="inherit" w="100%">
          <Flex minH="100%" w={{ base: "100%", md: "55%" }} px={{base: 5, md: 20}}  direction={"column"}>
            <Stack>
            <Link to={'/'}>
            <Image src="/logo/Logo.svg" w={250} mt={5} ml={"-50px"}></Image>
            </Link>

            </Stack>
            <Stack mt={"-5"} padding="16.4976px 23.9965px;" gap="5px" width={{ base: "80%", md: "100%" }} p={10}>
              <ToastContainer></ToastContainer>
              <GoBackButton url={'home'}></GoBackButton>
            <form onSubmit={handleSubmit} display={'flex'}>
              <Text fontWeight={500} fontFamily='Inter' fontSize={18} color={'#23262F'}>API Registration</Text>          
              <FormControl mb={'10px'} pb={'25px'}>
                  <CircularProgress  isIndeterminate id={"loader"}  maxH="10px" display={'none'} ></CircularProgress>  
                </FormControl>
            <FormControl>
                  <FormLabel color={'#87898E'} fontStyle="normal" fontWeight="500"
                    fontSize="14px" fontFamily='Manrope'>
                First Name
              </FormLabel>
                  <Input name="firstName" background="#FCFCFD" border="1.49978px solid #DFDFE6"
                    borderRadius="8.99867px" onChange={handleChange}></Input>
            </FormControl>
              
            <FormControl>
              <FormLabel color={'#87898E'} fontStyle="normal" fontWeight="500" fontSize="14px" fontFamily='Manrope'>
                Last Name
              </FormLabel>
                  <Input name="lastName" background="#FCFCFD" border="1.49978px solid #DFDFE6"
                    borderRadius="8.99867px"  onChange={handleChange}></Input>
            </FormControl>
              
            <FormControl>
              <FormLabel color={'#87898E'} fontStyle="normal" fontWeight="500" fontSize="14px" fontFamily='Manrope'>
                Email Address
              </FormLabel>
    
              <InputGroup>
                <InputLeftElement
                  pointerEvents='none'
                  children={<FaEnvelope />}
                />
                    <Input type='text' name="email" background="#FCFCFD"
                      border="1.49978px solid #DFDFE6" borderRadius="8.99867px"
                      onChange={handleChange} />
              </InputGroup>
              
            </FormControl>
            <FormControl>
              <FormLabel color={'#87898E'} fontStyle="normal" fontWeight="500" fontSize="14px" fontFamily='Manrope'>
                Country
              </FormLabel>
                
                <Select type="text" background="#FCFCFD" border="1.49978px solid #DFDFE6" borderRadius="8.99867px"
                  name={"country"} onChange={handleChange}>
                                        <option></option>
                                        {countries && (countries.map((country) => (
                                          <option value={country.name}>
                                            {country.name}
                                          </option>
                                        )))}
                                          </Select>
              </FormControl>
              
            <FormControl>
              <FormLabel color={'#87898E'} fontStyle="normal" fontWeight="500" fontSize="14px" fontFamily='Manrope'>
                Password
              </FormLabel>
                  <Input type={'password'} name="password" background="#FCFCFD"
                    border="1.49978px solid #DFDFE6" borderRadius="8.99867px"
                    onChange={handleChange}></Input>
              </FormControl>
              <br/>
                <Button 
                  type='submit'
                  mt={5}
                  background="#233065"
                  borderRadius="7.26072px"  
                  color={'white'}
                  flex-direction="row"
                  justifyContent="center"
                  alignItems="center"
                  padding="14.9978px 7.4989px"
                  w={'100%'}
                  flexGrow="0;">Register</Button>
                
              <Center>
                <Text fontSize={14} fontFamily={'Inter'}>
                  Already a member? <Link to="/login" color='#374151' fontWeight={700} ml={100}> Sign In </Link>
                  </Text>
                </Center>
                </form>
          </Stack>
              
            </Flex>
          <Flex h="100%" w="45%" bg="url(/images/bg-auth.png)" display={{ base: "none", md:"flex"}} >
            
            </Flex>
      </Flex>
      </Flex>
  </Flex>
  );
}


export default Register;