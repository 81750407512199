import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

const GoBackButton = ({url}) => {

  let navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  const handleHome = () => {
   navigate("/")
  };

  return (
    <IconButton
      onClick={url ? handleHome : handleGoBack}
      borderRadius={'100%'}
      w={30}
      variant="outline"
      aria-label="Go back"
      icon={<ArrowBackIcon />}
    />
  );
};

export default GoBackButton;
