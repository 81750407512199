import axios from 'axios';
export class Index {


  createUser = async (data) => { 
   
    try {
     const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
       method: 'POST',
       body: JSON.stringify(data),
       headers: {
           'Content-Type': 'application/json',
           'Access-Control-Allow-Origin': '*'
           
         // 'Content-Type': 'application/x-www-form-urlencoded',
       },
     })
     return response.json();
   } catch (error) {
     return error
   }
  }
  
  async login(user) {
    localStorage.clear();
  
    try {
      let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, JSON.stringify(user), {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      })
      
      localStorage.setItem('token', `Bearer ${data.token}`);
     return await this.fetchData(data)
      
    } catch (e) {
      return (e.response)
    }
  
  }
  
  
    async fetchData(user) {
      try {
        let options =   {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + user.token
          }
        }
        let datas = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, options)  
        return datas
      } catch (e) {
        return (e.response)
      }
  }
  findFPolicy = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/findFPolicy`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  findWP = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/findWP`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  findTP = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/findTP`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  findMore= async (page) => {
   
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/findResources/${page}`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  findSlider = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/findSlider`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  findDetail = async (id, page) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/${page}ById/${id}`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
           
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      return response.json();
    } catch (error) {
      return error
    }
  }
  
  createContact = async (data) => { 
  
    try {
     const response = await fetch(`${process.env.REACT_APP_API_URL}/contact`, {
       method: 'POST',
       body: JSON.stringify(data),
       headers: {
           'Content-Type': 'application/json',
           'Access-Control-Allow-Origin': '*'
           
         // 'Content-Type': 'application/x-www-form-urlencoded',
       },
     })
     return response.json();
   } catch (error) {
     return error
   }
  }
}